import React, { useEffect, useState} from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { TextField, Tooltip, IconButton } from "@mui/material"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useAxiosProtect from "../../../../../../hooks/useAxiosProtect";
import { HelpCenter } from "@mui/icons-material";

// Flowie Modal
const FlowieModal = props => {
    const axiosProtect = useAxiosProtect();

    // Flowie Variables
    const [flowieName, setFlowieName] = useState('');
    const [name, setName] = useState('');
    const [flowieLocation, setFlowieLocation] = useState('');
    const [alertLabsAccount, setAlertLabsAccount] = useState('Enviro-Stewards');
    // error message states
    const [flowieNameErr, setFlowieNameErr] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [flowieLocationErr, setFlowieLocationErr] = useState('');
    const [serverErr, setServerErr] = useState('');

    const changeFlowieName = e => {
        setFlowieName(e.target.value);
    }

    const changeName = e => {
        setName(e.target.value);
    }

    // Close model to refresh data
    const closeModal = () => {
        setFlowieName('');
        setName('');
        setFlowieLocation('');

        setNameErr('');
        setFlowieNameErr('');
        setFlowieLocationErr('');
        setServerErr('');

        props.toggleModalState(undefined);
        props.setFlowieModalData(null)
    }

    const isValidForm = () => {
        let isValid = true;

        setServerErr('')

        if(flowieName === ''){
            setFlowieNameErr('Required');
            isValid = false;
        }else setFlowieNameErr('');

        if(name === ''){
            setNameErr('Required');
            isValid = false;
        }else setNameErr('')

        if(flowieLocation === ''){
            setFlowieLocationErr('Required');
            isValid = false;
        }else setFlowieLocationErr('');
    
        return isValid;
    }

    const createFlowie = async () => {
        // Get site_id
        let site_id = parseInt(window.location.href.split('/').slice(-1))

        // Validate form
        if(!isValidForm()) return;

        try {
            const response = await axiosProtect.post('/sensors/alertLabs', {
                site_id: site_id,
                name: name,
                flowie_name: flowieName,
                flowie_location_name: flowieLocation,
                account_name: alertLabsAccount
            });
            // Add the new row to the table
            props.setFlowies(flowies => [...flowies, response.data.flowie])
            closeModal()
        } catch(err) {
            setServerErr(err.response.data.error);
        }
    }

    const deleteFlowie = async () => {
        const controller = new AbortController();

        try{
            const response = await axiosProtect.delete(`/sensors/alertLabs?flowie_id=${props.flowieModalData.id}`, {
                signal: controller.signal,
            });

            if(response.status === 200){
                props.removeRow(props.flowieModalData.id)
            }

            closeModal();
        }catch(err){
            console.log(err.response)
        }
    }

    const editFlowie = async () => {
        // Get site_id
        let site_id = parseInt(window.location.href.split('/').slice(-1))
        // Validate form
        if (isValidForm()) {
            try {
                const response = await axiosProtect.put(`/sensors/alertLabs`, {
                    name: name,
                    flowie_name: flowieName,
                    site_id: site_id
                });
                // Get rid of old row and put updated row into the table
                props.removeRow(props.flowieModalData.id)
                props.setFlowies(flowies => [...flowies, response.data.flowie])
                closeModal()
            } catch(err) {
                console.log(err.response.data)
            }
        } else {
            console.log('Form is incorrect')
        }
    }

    useEffect(() => {
        // Update form with default values for editing
        if (props.modalState === 'edit') {
            setEditFlowies()
        }
    }, [props.modalState])

    const setEditFlowies = () => {
        setName(props.flowieModalData.name)
        setFlowieName(props.flowieModalData.flowie_name)
    }

    return (
        <>
        <Modal show={props.modalState !== undefined} onHide={() => closeModal()} backdrop="static">
            <Modal.Header closeButton>
                {props.modalState === 'add' ? <h4>Add Flowie</h4> 
                    : props.modalState === 'delete' ? <h4>Delete Flowie</h4> 
                    : props.modalState === 'edit' ? <h4>Edit Flowie</h4>
                    : ''
                }
            </Modal.Header>
            <Modal.Body>
                {serverErr !== '' && <p style={{color:'red'}}>{serverErr}</p>}
                {props.modalState === 'add' || props.modalState === 'edit' ?
                    <Form>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">AlertLabs Account</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={alertLabsAccount}
                                onChange={e => setAlertLabsAccount(e.target.value)}
                            >
                                <FormControlLabel value="Enviro-Stewards" control={<Radio />} label="Enviro-Stewards" />
                                <FormControlLabel value="Gay Lea" control={<Radio />} label="Gay Lea" />
                            </RadioGroup>
                        </FormControl>

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                name="name"
                                value={name}
                                onChange={e => changeName(e)}
                                style={{width:'100%', marginBottom:'1.5vh', flex:'84'}}
                                error={nameErr !== ''}
                                helperText={nameErr}
                            />

                            <div style={{flex:'2'}}></div>

                            <Tooltip title="Given name of the unit or line being logged" style={{flex:'14'}}>
                                <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                    <HelpCenter/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="Flowie Name"
                                variant="outlined"
                                name="territory"
                                value={flowieName}
                                onChange={e => changeFlowieName(e)}
                                disabled={props.modalState === 'edit' ? true : false }
                                style={{width:'100%', marginBottom:'1.5vh', flex:'84'}}
                                error={flowieNameErr !== ''}
                                helperText={flowieNameErr}
                            />

                            <div style={{flex:'2'}}></div>

                            <Tooltip title="Value of the 'Sensor Name' field on the sensor settings page in AlertLabs" style={{flex:'14'}}>
                                <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                    <HelpCenter/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <TextField
                                label="Flowie location name"
                                variant="outlined"
                                name="Location name"
                                value={flowieLocation}
                                onChange={e => setFlowieLocation(e.target.value)}
                                style={{width:'100%', marginBottom:'1.5vh', flex:'84'}}
                                error={flowieLocationErr !== ''}
                                helperText={flowieLocationErr}
                            />

                            <div style={{flex:'2'}}></div>

                            <Tooltip title="Value of the 'Property' field on the sensor settings page in AlertLabs" style={{flex:'14'}}>
                                <IconButton sx={{ m: 1 }} style={{backgroundColor:'white'}}>
                                    <HelpCenter/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Form>

                : props.modalState === 'delete' ?
                    <>
                        <p>Are you sure you want to delete the following flowie?</p>
                        <p>
                            {props.flowieModalData.name}
                        </p>
                    </>
                : props.modalState === 'toggle' ?
                    <p>
                        <strong>WARNING</strong> Are you sure you want to change the state of this flowie?
                    </p>
                : <div></div>
                }
            </Modal.Body>
            <Modal.Footer>
                    <Button 
                        // variant={props.modalState === 'toggle' ? 'primary' : 'danger'}
                        style={{backgroundColor: 'rgb(8,114,182)'}}
                        type='submit'
                        onClick={() => { 
                            if (props.modalState === 'delete') {
                                deleteFlowie() 
                            } else if (props.modalState === 'add') {
                                createFlowie()
                            } else if (props.modalState === 'edit') {
                                editFlowie()
                            } else if (props.modalState === 'toggle') {
                                props.toggleFlowieStatus(props.flowieModalData.id)
                            }
                        }}
                        >
                            {props.modalState === 'add' ? 'Create' 
                            :props.modalState === 'edit' ? 'Edit'
                            :props.modalState === 'delete' ? 'Delete'
                            :props.modalState === 'toggle' ? 'Confirm'
                            : ''}
                    </Button>
            </Modal.Footer>
        </Modal>
        </>
      );
  }

export default FlowieModal;