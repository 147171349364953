import React, { useEffect, useRef, useState } from 'react';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';

const VariableSavings = props => {
    let init = useRef(true);

    const [savingsData, setSavingsData] = useState([]);
    const [targetPercents, setTargetPercents] = useState({
        water: 0,
        electricity: 0,
        gas: 0,
    });

    useEffect(() => {
        // if(!init) return;
        // init = false;

        if(props.savingsData.length === 0) return;

        let data = [...props.savingsData];
        let waterSaved = 0, elecSaved = 0, gasSaved = 0;

        for(let i = 0;i<data.length;++i){
            waterSaved += data[i].water;
            elecSaved += data[i].electricity;
            gasSaved += data[i].gas;
        }

        let waterAverage = 0, elecAverage = 0, gasAverage = 0;
        waterAverage = data[0].waterTarget === 0 ? 0 : waterSaved / data.length;
        elecAverage = data[0].electricityTarget === 0 ? 0 : elecSaved / data.length;
        gasAverage = data[0].gasTarget === 0 ? 0 : gasSaved / data.length;

        setTargetPercents(prev => ({
            ...prev,
            water: data[0].waterTarget === 0 ? 0 : Math.round(100 * waterAverage / data[0].waterTarget),
            electricity: data[0].electricityTarget === 0 ? 0 : Math.round(100 * elecAverage / data[0].electricityTarget),
            gas: data[0].gasTarget === 0 ? 0 : Math.round(100 * gasAverage / data[0].gasTarget)
        }))

        for(let i = 0;i<data.length;++i){
            data[i].waterAverage = waterAverage;
            data[i].electricityAverage = elecAverage;
            data[i].gasAverage = gasAverage;
        }

        setSavingsData(data);
    }, [props.savingsData]);

    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
    
        return `${day}/${month}/${year}`;
    };

    return(
        <ResponsiveContainer height='100%' width='100%'>
                <ComposedChart data={savingsData}>
                    <XAxis dataKey="time" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                         textAnchor='middle' tickFormatter={formatXAxisTick}/>

                    <YAxis yAxisId="left" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}}
                        label={{value: `${props.activeMetric.value.charAt(0).toUpperCase() + props.activeMetric.value.slice(1)} savings (${props.activeMetric.value === 'electricity' ? 'kWh' : 'm3'})`,
                        position: 'outside', offset: 0, angle: -90, dx: -24, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}/>
                    
                    <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}}
                        label={{value: ``,
                        position: 'outside', offset: 0, angle: 90, dx: 24, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}
                    />
                    
                    {/* <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }}
                        labelFormatter={formatXAxisTick}
                        wrapperStyle={{ outline: "none" }}
                        // formatter={formatReadings}
                    /> */}

                    <Legend verticalAlign='top' iconType='line' align='center' iconSize={8} 
                        payload={
                            [
                                {value: `Savings (${props.activeMetric.value === 'electricity' ? 'kWh' : 'm3'})`, type: 'circle', color:'rgb(8,114,182)'},
                                {value: `Target savings (${props.activeMetric.value === 'electricity' ? 'kWh' : 'm3'}/day)`, type: 'circle', color:'rgb(213,43,0)'},
                                {value: `Average savings (${props.activeMetric.value === 'electricity' ? 'kWh' : 'm3'}/day) (${targetPercents[props.activeMetric.value]}% of target)`, type: 'circle', color:'rgb(0,175,180)'}
                            ]
                        }
                    />

                    <CartesianGrid stroke="#f5f5f5"/>
                    
                    {props.activeMetric.value !== '' && <Bar yAxisId="left" dataKey={`${props.activeMetric.value}`} fill={'rgb(8,114,182)'}  />}
                    {props.activeMetric.value !== '' && <Line yAxisId="left" type="monotone" dataKey={`${props.activeMetric.value}Target`} strokeWidth={2} stroke={'rgb(213,43,0)'} dot={false}/>}
                    {props.activeMetric.value !== '' && <Line yAxisId="left" type="monotone" dataKey={`${props.activeMetric.value}Average`} strokeWidth={2} stroke={'rgb(0,175,180)'} dot={false}/>}
                </ComposedChart>
            </ResponsiveContainer>
    );
}

export default VariableSavings;