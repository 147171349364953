import React, {useEffect, useRef, useState} from 'react';
import SummaryCard from './dashWidgets/SummaryCard';
import DashGraph from './dashWidgets/DashGraph';
import EmissionsModal from './dashWidgets/EmissionsModal';
import ParetoGraph from './dashWidgets/ParetoGraph';
import './DashStyle.css';
import SiteSummary from './dashWidgets/SiteSummary';
import OpportunityCard from './dashWidgets/OpportunityCard';
// data for guest view
import exampleData from '../../../example_data/dashboard/compositeData';
import sites from '../../../example_data/dashboard/siteData';
// import paretoData from '../../../example_data/dashboard/paretoData';
import useAuth from '../../../hooks/useAuth';
import jwtDecode from 'jwt-decode';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import DisclaimerSnackbar from './DisclaimerSnackbar';
import OpportunityTable from '../../opportunities/OpportunityTable';
import IconButton from '@mui/material/IconButton';
import ReportModal from '../staff_dashboard/settings/ReportModal';
import OpportunityTableRev2 from '../../opportunities/OpportunityTableRev2';

const DashboardContainer = props => {
    const axiosProtect = useAxiosProtect();
    var init = useRef(true);
    const {auth, persist} = useAuth();
    const [reportModalOpen, setReportModalOpen] = useState(false);

    const [siteName, setSiteName] = useState('');

    const [paretoData, setParetoData] = useState([]);

    const [waterData, setWaterData] = useState({
        scalarValue: null,
        units: 'L',
        ghg: 0,
        cost: 0,
        totalCost: 0,
        present: true
    });

    const [elecData, setElecData] = useState({
        scalarValue: null,
        units: 'kWh',
        ghg: 0,
        cost: 0,
        totalCost: 0,
        present: true
    });

    const [gasData, setGasData] = useState({
        scalarValue: null,
        units: <span>m<sup>3</sup></span>,
        ghg: 0,
        cost: 0,
        totalCost: 0,
        present: true

    });

    const [ghgData, setGHGData] = useState({
        scalarValue: null,
        units: <span>kg CO<sub>2</sub>e</span>,
        cost: null,
        totalCost: 0,
        present: true
    });

    const [opportunities, setOpportunities] = useState([]);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if(init.current){
            init.current = false;

            const renderSiteData = async () => {
                let clientDetails = resolveClientType();
            
                if(!clientDetails){
                    setWaterData(prev => ({
                        ...prev,
                        scalarValue: 7351,
                        ghg: 7351 * 0.036 / 1000,
                        cost: 8521
                    }));

                    setElecData(prev => ({
                        ...prev,
                        scalarValue: 8574,
                        ghg: 8574 * 0.028,
                        cost: 10682
                    }));

                    setGasData(prev => ({
                        ...prev,
                        scalarValue: 349,
                        ghg: 349 * 1.921,
                        cost: 524
                    }));

                    setGHGData(prev => ({
                        ...prev,
                        scalarValue: 349 * 1.921 + 8574 * 0.028 + 7351 * 0.036 / 1000,
                        cost: 8574
                    }));

                }else{
                    let siteID = window.location.href.split('/')[window.location.href.split('/').length - 1];

                    try{
                        let siteAccess = await axiosProtect.get(`/auth/query/check-client-site-access?user_id=${clientDetails.id}&role=${clientDetails.role}&org_id=${clientDetails.org_id}&site_id=${siteID}`);
                        if(!siteAccess.data.hasAccess) window.history.back();
                        setSiteName(siteAccess.data.siteName);
                    }catch(error){
                        window.history.back();
                    }

                    axiosProtect.get(`/c/client/card?siteID=${siteID}`).then(res => {
                        if(res.data.water){
                            setWaterData(prev => ({
                                ...prev,
                                scalarValue: res.data.water.use,
                                ghg: res.data.water.co2_kg,
                                cost: parseInt(res.data.water.cost),
                                totalCost: parseInt((res.data.water ? res.data.water.cost : 0) + (res.data.electricity? res.data.electricity.cost : 0) + (res.data.gas? res.data.gas.cost : 0))
                            }));
                        }else{
                            setWaterData(prev => ({
                                ...prev,
                                present:false
                            }));
                        }

                        if(res.data.electricity){
                            setElecData(prev => ({
                                ...prev,
                                scalarValue: res.data.electricity.use,
                                ghg: res.data.electricity.co2_kg,
                                cost: parseInt(res.data.electricity.cost),
                                totalCost: parseInt((res.data.water ? res.data.water.cost : 0) + (res.data.electricity? res.data.electricity.cost : 0) + (res.data.gas? res.data.gas.cost : 0))
                            }));
                        }else{
                            setElecData(prev => ({
                                ...prev,
                                present: false
                            }));
                        }

                        if(res.data.gas){
                            setGasData(prev => ({
                                ...prev,
                                scalarValue: res.data.gas.use / 35300,
                                ghg: res.data.gas.co2_kg,
                                cost: parseInt(res.data.gas.cost),
                                totalCost: parseInt((res.data.water ? res.data.water.cost : 0) + (res.data.electricity? res.data.electricity.cost : 0) + (res.data.gas? res.data.gas.cost : 0))
                            }));
                        }else{
                            setGasData(prev => ({
                                ...prev,
                                present: false
                            }));
                        }

                        setGHGData(prev => ({
                            ...prev,
                            scalarValue: (res.data.water ? res.data.water.co2_kg : 0) + (res.data.electricity? res.data.electricity.co2_kg : 0) + (res.data.gas? res.data.gas.co2_kg : 0),
                            cost: 8574,
                            totalCost: parseInt((res.data.water ? res.data.water.cost : 0) + (res.data.electricity? res.data.electricity.cost : 0) + (res.data.gas? res.data.gas.cost : 0))
                        }));
                    });

                    axiosProtect.get(`/c/client/pareto?siteID=${siteID}`).then(res => {
                        let data = res.data.data.sort((a, b) => {return b.percent - a.percent});
                        setParetoData(data.length === 0 ? null : data)
                    });

    //                 axiosProtect.get(`/opp/opportunities/get-opps?site_id=${siteID}`).then(response => {
    //                     setOpportunities(response.data.opportunities)
    // ;                }).catch(error => {
    //                     console.log(error)
    //                 });
                }
            }

            renderSiteData();
        }
    }, []);

    const resolveClientType = () => {
        if(auth == null) return null;

        let tokenObj = jwtDecode(auth.accessToken);

        if(tokenObj.email === "testcorporateclient@test.com") return null;
        return tokenObj;
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }
    
    return(
        <div style={{padding:'0.25% 2% 2% 2%'}} id='nav-ctr'>
            {/* <DisclaimerSnackbar/> */}
            {reportModalOpen && <ReportModal reportModalOpen={reportModalOpen} setReportModalOpen={setReportModalOpen} siteName={''} />}

            <p style={{fontSize:'0.8rem'}}>NOTE: All values on this page only account for units actively being monitored by Stewwi. Totals may not reflect 100% of the site's consumption.</p>

            <div style={{width:'100%', display:'flex', flexDirection:'row'}}>
                <IconButton style={{flex:'1.5', borderRadius:'2%', backgroundColor:'rgba(8,114,182, 0.1)', marginBottom:'2vh'}} color="info">
                    <p style={{margin:'1% 0', fontSize:'1rem'}} onClick={() => {
                        setReportModalOpen(true);
                    }}>Generate site report</p>
                </IconButton>

                <div style={{flex:'8', display:'flex', alignItems:'center', justifyContent:'center', paddingBottom:'0.5%'}}>
                    <h4>{siteName}</h4>
                </div>

                <IconButton style={{flex:'1.5', borderRadius:'2%', backgroundColor:'rgba(0,177,168,0.1)', marginBottom:'2vh'}} color="rgb(0,177,168)">
                    <p style={{margin:'1% 0', fontSize:'1rem'}} onClick={() => {
                        window.location.href = `/schedule/${window.location.href.split('/')[window.location.href.split('/').length - 1]}`;
                    }}>Schedule Manager</p>
                </IconButton>
            </div>

            <EmissionsModal closeModal={closeModal} showModal={showModal}/>
            
            <div id='overview-cards'>
                <SummaryCard title="Water" value={waterData.scalarValue} totalCost={waterData.totalCost} units={waterData.units} cost={waterData.cost} present={waterData.present} windowName='water'/>
                <SummaryCard title="Electricity" value={elecData.scalarValue} totalCost={elecData.totalCost} units={elecData.units} cost={elecData.cost} present={elecData.present} windowName='electricity'/>
                <SummaryCard title="Natural Gas" value={gasData.scalarValue} totalCost={gasData.totalCost} units={<span>m<sup>3</sup></span>} cost={gasData.cost} present={gasData.present} windowName='gas'/>
                <SummaryCard title="Emissions" value={ghgData.scalarValue} totalCost={ghgData.totalCost} units={<span>kg CO<sub>2</sub>e</span>} cost={ghgData.cost}
                waterGHG={waterData.ghg} elecGHG={elecData.ghg} gasGHG={gasData.ghg} openModal={openModal} present={ghgData.present}/>
            </div>

            <div id='overview-trends'>
                <div className='summary-trend'>
                    <DashGraph title='Water Usage' primary='rgb(8,114,182)' secondary="rgb(0,177,168)" utility='water'/>
                </div>

                <div className='summary-trend'>
                    <DashGraph title='Electricity Usage' primary='rgb(0,177,168)' secondary='rgb(8,114,182)' utility='electricity'/>
                </div>
            </div>

            <div id='opp-data'>
                {/* <OpportunityTable/> */}
                <OpportunityTableRev2/>
            </div>

            <div id='misc-data'>
                <div id='elec-pareto'>
                    <ParetoGraph primary='rgb(8,114,182)' data={paretoData}/>
                </div>

                {/* <div id='quick-summary'>
                    <div id='site-card'>
                        <OpportunityCard opportunities={opportunities}/>
                    </div>

                    <div id='summary-buffer'></div>

                    <div id='opp-card'>
                        <SiteSummary sites={sites}/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default DashboardContainer;