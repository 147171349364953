import React, {useState} from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EventIcon from '@mui/icons-material/Event';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AlertsModal from "./AlertsModal";

const Toolbar = props => {
    const [alertsModalOpen, setAlertsModalOpen] = useState(false);

    const convertToCSV = arr => {
        // ID and create headers
        const unitKeys = Array.from(new Set(arr.flatMap(Object.keys)));
        const headers = ['time', ...unitKeys.filter(key => key !== 'time').map(header => {
            if (props.plotSpecs.activeUtility === 'electricity') {
                return header === 'events' ? header :`${header} ${props.plotSpecs.metricType.split(' ')[1]}`;
            } else if (props.plotSpecs.activeUtility === 'water') {
                return header === 'events' ? header : `${header} (L)`;
            }else if(props.plotSpecs.activeUtility === 'gas'){
                return header === 'events' ? header : `${header} (m3)`;
            }
            return header;
        })];

        // map data to csv, convert timestamp to local (get site tz in future)
        const csvContent = arr.map(row => {
            return headers.map(header => {
                if (header.startsWith('time')) {
                    let time = new Date(row[header]);

                    const timeOffset = time.getTimezoneOffset() * 60 * 1000;
                    let adjustedTime = new Date(time.getTime() - timeOffset);
    
                    const day = ('0' + adjustedTime.getDate()).slice(-2);
                    const month = ('0' + (adjustedTime.getMonth() + 1)).slice(-2);
                    const year = adjustedTime.getFullYear();
                    const hours = ('0' + adjustedTime.getHours()).slice(-2);
                    const minutes = ('0' + adjustedTime.getMinutes()).slice(-2);
                    
                    return `${day}-${month}-${year} ${hours}:${minutes}`;
                }else {
                    // Extract the original key from the header (had to append ( unit))
                    // const originalKeyMatch = header.match(/^(.*?)\s*(\(|$)/);
                    // const originalKey = originalKeyMatch ? originalKeyMatch[1] : header;
                    // console.log(originalKey)
                    let terms = header.split(' ');
                    terms.pop();
                    const originalKey = terms.join(' ');
                    return originalKey in row ? (typeof row[originalKey] === 'object' ? row[originalKey].current : row[originalKey]) : '';
                }
            }).join(',');
        });

        return [headers.join(','), ...csvContent].join('\n');
    }

    const downloadCSV = () => {
        const csv = convertToCSV(props.data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `Site_${props.plotSpecs.activeUtility}_data.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    return (
        <>
        {alertsModalOpen && <AlertsModal alertsModalOpen={alertsModalOpen} setAlertsModalOpen={setAlertsModalOpen} />}
        <div className="chart-graph-controls">
            <div style={{flex:'5'}}>
                <Box sx={{ width: '95%'}}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Utility</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.plotSpecs.activeUtility}
                            label="Utility"
                            onChange={e => props.updatePlotSpecs(e, 'activeUtility')}
                        >
                            <MenuItem value='water' disabled={!props.scopeData.hasWater}>Water</MenuItem>
                            <MenuItem value='electricity' disabled={!props.scopeData.hasElec}>Electricity</MenuItem>
                            <MenuItem value='gas' disabled={!props.scopeData.hasGas}>Gas</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>

            <div style={{flex:'5'}}>
                <Box sx={{ width: '95%'}}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Metric</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.plotSpecs.metricType}
                            label="Metric"
                            onChange={e => props.updatePlotSpecs(e, 'metricType')}
                        >
                            {props.plotSpecs.metricOptions.map((value, _) => (
                                <MenuItem value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>

            <div style={{flex:'5'}}>
                <Box sx={{ width: '95%'}}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Timeframe</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.plotSpecs.timeframe}
                            label="Timeframe"
                            onChange={e => {
                                if(e.target.value !== 'custom'){
                                    props.updatePlotSpecs(e, 'timeframe')
                                }
                            }}
                        >
                            <MenuItem value='1h'>Last hour</MenuItem>
                            <MenuItem value='4h'>4 hours</MenuItem>
                            <MenuItem value='12h'>12 hours</MenuItem>
                            <MenuItem value='1d'>Day</MenuItem>
                            <MenuItem value='7d'>7 days</MenuItem>
                            <MenuItem value='30d'>Month</MenuItem>
                            <MenuItem value='365d'>Year</MenuItem>
                            <MenuItem value='custom' onClick={() => props.openModal()}>Custom</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </div>

            <div style={{flex:'5'}}>
                <Box sx={{ width: '95%'}}>
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">Resolution</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.plotSpecs.resolution}
                            label="Resolution"
                            onChange={e => props.updatePlotSpecs(e, 'resolution')}
                        >
                            {(props.plotSpecs.resolution === '1m' || ['1h','4h','12h','1d'].includes(props.plotSpecs.timeframe)) && <MenuItem value='1m'>1 minute</MenuItem>}
                            {(props.plotSpecs.resolution === '5m' || ['1h','4h','12h','1d'].includes(props.plotSpecs.timeframe)) && <MenuItem value='5m'>5 minutes</MenuItem>}
                            {(props.plotSpecs.resolution === '15m' || ['4h','12h','1d'].includes(props.plotSpecs.timeframe)) && <MenuItem value='15m'>15 minutes</MenuItem>}
                            {(props.plotSpecs.resolution === '1h' || ['12h','1d','7d','30d'].includes(props.plotSpecs.timeframe)) && <MenuItem value='1h'>1 hour</MenuItem>}
                            {(props.plotSpecs.resolution === '12h' || ['7d','30d','365d'].includes(props.plotSpecs.timeframe)) && <MenuItem value='12h'>12 hours</MenuItem>}
                            {(props.plotSpecs.resolution === '1d' || ['7d','30d','365d'].includes(props.plotSpecs.timeframe)) && <MenuItem value='1d'>1 day</MenuItem>}
                        </Select>
                    </FormControl>
                </Box>
            </div>

            <div style={{flex:'1', display:'flex', flexDirection:'row'}}>
                <Tooltip title="Manage alerts">
                    <IconButton style={{flex:'1', borderRadius:'0'}} color="info" onClick={() => {
                        setAlertsModalOpen(true);
                    }}>
                        <NotificationAddIcon/>
                    </IconButton>
                </Tooltip>
            </div>

            <div style={{flex:'0.25'}}></div>

            <div style={{flex:'1', display:'flex', flexDirection:'row'}}>
                <Tooltip title="Add event">
                    <IconButton style={{flex:'1', borderRadius:'0'}} color="info" onClick={() => props.setEventsModalOpen(true)}>
                        <EventIcon/>
                    </IconButton>
                </Tooltip>
            </div>

            <div style={{flex:'0.25'}}></div>

            <div style={{flex:'1', display:'flex', flexDirection:'row'}}>
                <Tooltip title="Download CSV">
                    <IconButton style={{flex:'1', borderRadius:'0'}} color="info" onClick={() => downloadCSV()}>
                        <FileDownloadIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
        </>
    );
}

export default Toolbar;