import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes, // instead of "Switch"
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import LandingPage from './components/auth/LandingPage';
import DashboardContainer from './components/dashboard/site_dashboard/DashboardContainer';
import RMISNavbar from './components/navigation/RMISNavbar';
import ClientGraphContainer from './components/metric_graphs/utilities/raw_data/components/client_version/ClientGraphContainer';
import './App.css'
import Container from './components/opportunities/overview/Container';
import InfographicContainer from './components/test/infographics/InfographicContainer';
import StaffDashContainer from './components/dashboard/staff_dashboard/StaffDashContainer';
import PersistLogin from './components/PersistLogin';
import ClientDashContainer from './components/dashboard/client_dashboard/ClientDashContainer';
import useAuth from './hooks/useAuth';
import EmissionsDashboard from './components/emissions/EmissionsDashboard';
import GHGFactors from './components/staff_pages/ghg_factors/GHGFactors';
import OrgManagementPage from './components/dashboard/staff_dashboard/OrgManagementPage';
import SiteManagementPage from './components/dashboard/staff_dashboard/SiteManagementPage';

import ProfilePage from './components/auth/ProfilePage';

import { createContext } from 'react';
import ScrollToTop from './components/navigation/ScrollToTop';
import MaintenanceContainer from './components/maintenance/MaintenanceContainer';
import OpportunityContainer from './components/opportunities/OpportunityContainer';
import ReportContainer from './components/reporting/ReportContainer';
import UserAgreement from './components/global/UserAgreement';
import ProjectManagementContainer from './components/implementation/ProjectManagementContainer';

import { generateClientCrumbs, generateStaffCrumbs, staffCrumbs } from './breadcrumbData';
import { isMobile } from 'react-device-detect';
import MobileLandingPage from './mobile/MobileLandingPage';
import DashboardRouter from './components/dashboard/DashboardRouter';
import OpportunityListContainer from './components/opportunities/OpportunityListContainer';
import SetPassword from './components/auth/SetPassword';
import PricingContainer from './components/pricing/PricingContainer';
import EventsContainer from './components/events/EventsContainer';
import BlueRoofContainer from './components/blueroof/BlueRoofContainer';
import OverviewContainer from './components/dashboard/overview/OverviewContainer';
import ActivityLogContainer from './components/activity_log/ActivityLogContainer';
import CustomerFeedback from './components/navigation/CustomerFeedback';
import CustomerManagementContainer from './components/customer_management/CustomerManagementContainer';
import HMartContainer from './components/hmart/HMartContainer';
import ExampleGraph from './example_data/utilityGraphs/ExampleGraph';

export const ThemeContext = createContext("light");

const App = () => {
  // dynamic rendering and responsive states
  const [isPortrait, setIsPortrait] = useState(false);
  const {auth} = useAuth();

  const [userAgreementOpen, setUserAgreementOpen] = useState(false);

  const [currentPath, setCurrentPath] = useState([staffCrumbs['home']]);

  const [userData, setUserData] = useState(undefined); // use to access all auth token data

  const checkTOSCompliance = () => {
    if(window.location.pathname === '/' || Object.keys(auth).length === 0) return;
    if(auth?.agreed_to_tos === undefined || auth?.agreed_to_tos === false) setUserAgreementOpen(true); 
  }

  const getURLSegments = () => {
    const urlObj = new URL(window.location.href);
    const pathSegments = urlObj.pathname.split('/').filter(segment => segment !== '');

    return pathSegments;
  }

  const buildStaffPath = async segments => {
    let staffPath = [];

    if(segments.length > 0) staffPath = await generateStaffCrumbs(segments);

    setCurrentPath([...currentPath, ...staffPath]);
  }

  const buildClientPath = async segments => {
    let clientPath = [];

    if(segments.length > 0) clientPath = await generateClientCrumbs(segments);

    setCurrentPath([...currentPath, ...clientPath]);
  }

  useEffect(() => {
    const setPortraitState = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    }

    window.addEventListener('resize', setPortraitState);

    return () => {
      window.removeEventListener('resize', setPortraitState);
    }
  }, []);

  useEffect(() => {
    if(auth == null) return;
    setUserData(auth);
    
    if(Object.keys(auth).length === 0) return;

    const isStaff = parseInt(auth?.role) >= parseInt(process.env.REACT_APP_ES_USER) && parseInt(auth?.role) <= parseInt(process.env.REACT_APP_ES_ADMIN);
    const urlSegments = getURLSegments();

    if(!isStaff){
      checkTOSCompliance();
      buildClientPath(urlSegments);
    }else{
      buildStaffPath(urlSegments);
    }

  }, [auth]);

  const location = useLocation();

  const [theme, setTheme] = useState("light")

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  }

  return (
    <ThemeContext.Provider value={{theme, toggleTheme}}>
      <div className="App" id={theme}>

      <UserAgreement userAgreementOpen={userAgreementOpen} setUserAgreementOpen={setUserAgreementOpen} auth={auth}/>

      {(location.pathname !== '/' && !location.pathname.startsWith('/report') && !isMobile) 
      && !location.pathname.startsWith('/infographics') && !location.pathname.startsWith('/activate') && !location.pathname.startsWith('/reset') 
      && <RMISNavbar currentPath={currentPath}/>}
      {/* {location.pathname !== '/' && !location.pathname.startsWith('/infographics') && <BreadcrumbBar/>} */}

      <ScrollToTop/>

      {(auth?.role === process.env.REACT_APP_CLIENT_ADMIN || auth?.role === process.env.REACT_APP_CLIENT_STAFF) && <CustomerFeedback/>}

      {
        isMobile ? (
          <Routes>
            <Route element={<PersistLogin/>}>
              <Route path='*' element={<MobileLandingPage/>}/>
            </Route>
          </Routes>
        ) : (
          <Routes>
            <Route element={<PersistLogin/>}>
              <Route path='/' element={<LandingPage isPortrait={isPortrait}/>}/>

              <Route path='/dashboard' element={
                // auth?.role === process.env.REACT_APP_CLIENT_ADMIN || auth?.role === process.env.REACT_APP_CLIENT_STAFF ?  <ClientDashContainer isPortrait={isPortrait}/> 
                // : auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <StaffDashContainer isPortrait={isPortrait}/> :
                // <Navigate to='/' replace />
                <DashboardRouter auth={auth} isPortrait={isPortrait}/>
              }/>

              <Route path='/ghg-factors' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <GHGFactors isPortrait={isPortrait}/> :
                <Navigate to='/' replace />
              }/>

              <Route path='/client/*' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <OrgManagementPage isPortrait={isPortrait}/> :
                <Navigate to='/' replace /> // possibly change from redirect to client's version of page once created
              }/>

              <Route path='/sites/*' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <SiteManagementPage isPortrait={isPortrait} userData={userData}/> :
                <Navigate to='/' replace /> // possibly change from redirect to client's version of page once created
              }/>

              <Route path='/pricing/*' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <PricingContainer isPortrait={isPortrait}/> :
                <Navigate to='/' replace /> // possibly change from redirect to client's version of page once created
              }/>

              <Route path='/blueroof/*' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER || auth?.role === process.env.REACT_APP_BLUE_ROOF_CLIENT ? <BlueRoofContainer isPortrait={isPortrait} auth={auth}/> :
                <Navigate to='/' replace /> // possibly change from redirect to client's version of page once created
              }/>

              <Route path='/customer-management/*' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <CustomerManagementContainer isPortrait={isPortrait}/> :
                <Navigate to='/' replace /> // possibly change from redirect to client's version of page once created
              }/>

              <Route path='/hmart' element={
                auth?.role === process.env.REACT_APP_ES_ADMIN || auth?.role === process.env.REACT_APP_ES_USER ? <HMartContainer isPortrait={isPortrait}/> :
                <Navigate to='/' replace /> // possibly change from redirect to client's version of page once created
              }/>

              {/* <Route path='/guest' element={<DashboardContainer isPortrait={isPortrait}/>}/> */}
              <Route path='/site/*' element={<DashboardContainer isPortrait={isPortrait}/>}/>
              <Route path='/energy-metrics/*' element={<ClientGraphContainer isPortrait={isPortrait} userData={userData}/>}/>
              <Route path='/opportunities' element={<OpportunityListContainer isPortrait={isPortrait} userData={userData}/>}/>
              <Route path='/opportunities/*' element={<OpportunityContainer isPortrait={isPortrait}/>}/>
              {/* <Route path='/opportunities-guest' element={<Container isPortrait={isPortrait}/>}/> */}
              <Route path='/infographics/*' element={<InfographicContainer isPortrait={isPortrait}/>}/>
              
              {/* <Route path='/emissions' element={<EmissionsDashboard isPortrait={isPortrait} userData={userData}/>}/> */}
              <Route path='/emissions-savings' element={<EmissionsDashboard isPortrait={isPortrait} userData={userData} page='Overview'/>}/>
              <Route path='/emissions-trends' element={<EmissionsDashboard isPortrait={isPortrait} userData={userData} page='Trends'/>}/>
              
              <Route path='/maintenance' element={<MaintenanceContainer isPortrait={isPortrait}/>}/>
              <Route path='/report/*' element={<ReportContainer isPortrait={isPortrait}/>}/>
              <Route path='/activate/*' element={<SetPassword isPortrait={isPortrait}/>}/>
              <Route path='/reset/*' element={<SetPassword isPortrait={isPortrait}/>}/>

              <Route path='/projects/*' element={<ProjectManagementContainer isPortrait={isPortrait}/>}/>
              <Route path='/schedule/*' element={<EventsContainer isPortrait={isPortrait}/>}/>

              <Route path='/activity-log/*' element={<ActivityLogContainer isPortrait={isPortrait}/>}/>
              
              {/* <Route path='/overview/*' element={<OverviewContainer isPortrait={isPortrait} />}/> */}
              
              {/* <Route path='/staff' element={<StaffDashContainer isPortrait={isPortrait}/>}/> */}

              <Route path='/profile' element={<ProfilePage isPortrait={isPortrait}/>}/>

              {/* <Route path='/demo-data' element={<ExampleGraph isPortrait={isPortrait}/>}/> */}
            </Route>
          </Routes>
        )
      }
      </div>
    </ThemeContext.Provider>
      
  );
}

export default App;