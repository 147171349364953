import React from 'react';
import {Line, Tooltip, CartesianGrid, XAxis,YAxis, ResponsiveContainer, Bar, ComposedChart, Cell} from 'recharts';
import colors from '../../../../../example_data/colors';
import { Skeleton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import '../styling/graphStyle.css'

const Graph = props => {
    const formatXAxisLabel = (time) => {
        let offset = new Date().getTimezoneOffset();
        let date = new Date(time);
        date.setMinutes(date.getMinutes() - offset);

        let timeFieldObj = {};

        if(!props.plotSpecs.resolution.endsWith('d')){
            timeFieldObj.hour = '2-digit';
            timeFieldObj.minute = '2-digit';
            timeFieldObj.timeZone = 'America/New_York';
        }

        if(props.plotSpecs.timeframe.endsWith('d') || props.plotSpecs.timeframe === 'custom'){
            timeFieldObj.month = '2-digit';
            timeFieldObj.day = '2-digit';

            if(props.plotSpecs.timeframe !== '1d') timeFieldObj.year = 'numeric';
        }

        const formattedTime = date.toLocaleString('en-UK', timeFieldObj);

        return formattedTime;
    }

    const removeLastDot = str => {
        const lastDotIndex = str.lastIndexOf('.');

        if (lastDotIndex !== -1) {
            return str.slice(0, lastDotIndex);
        }

        return str;
    }

    const formatReadings = (reading, name) => {
        if(name === 'events.value'){
            return [reading, 'Events']
        }
        return [(Math.round(reading * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","), removeLastDot(name)]
    }

    const formatYAxisTick = tick => {
        if (Math.abs(tick) >= 1e6) return (tick / 1e6).toFixed(0) + 'M'; 
        else if (Math.abs(tick) >= 1e3) return (tick / 1e3).toFixed(0) + 'k';
        else return tick.toLocaleString();
    }

    const CustomizedTick = params => {
        const { x, y, payload } = params;

        const formattedTime = formatXAxisLabel(payload.value)

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill="rgb(78,79,83)" fontSize='12px'>
                {
                    (props.plotSpecs.timeframe.endsWith('d') && !props.plotSpecs.resolution.endsWith('d')) ? 
                    <>
                        <tspan textAnchor="middle" x="0">
                            {formattedTime.split(' ')[1]}
                        </tspan>
                        <tspan textAnchor="middle" x="0" dy="14">
                            {formattedTime.split(' ')[0].substring(0, formattedTime.split(' ')[0].length - 1)}
                        </tspan>
                    </> : 
                    <tspan textAnchor="middle" x="0">
                        {formattedTime}
                    </tspan>
                }
                </text>
            </g>
        );
    }

    return(
        <ResponsiveContainer>
            {props.errorMessage !== '' ? <div id="raw-data-graph-error-msg-container">
                <WarningIcon style={{height:'15%', width:'auto', marginTop:'-12%', color:'red'}}/>
                <br/>
                <h4 style={{textAlign:'center'}}>{props.errorMessage}</h4>
            </div> : 

            (props.data == null ? true : props.data.length === 0) ? 
                <Skeleton variant="rounded" animation='wave' width={210} height={59}/> :
            
                <ComposedChart data={props.data} id='metrics-linechart' margin={{ top: 5, right: 15, left: 0, bottom: 10 }} animation={{duration: 250}}>
                    <CartesianGrid strokeDasharray="2 8" />

                    <XAxis dataKey="time" interval={Math.ceil(props.data.length / 7)} grid={false} className="x-axis" 
                        tick={<CustomizedTick />}/>
                        
                    <YAxis grid={false} allowDecimals={false} yAxisId="left" className="y-axis" tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}} domain={[0, props.maxYValue]} tickFormatter={formatYAxisTick}
                        label={{value: `${props.plotSpecs.metricType}`, position: 'outside', offset: 0, angle: -90, dx: -14, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/>

                    <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} allowDecimals={false} tick={{ fontSize: '12px', fill: 'rgb(78,79,83)'}}
                        label={{value: `Events`,position: 'outside', offset: 0, angle: 90, dx: 30, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}/>

                    <Tooltip 
                        contentStyle={{
                            backgroundColor: '#F5F5F5',
                            color: '#333',
                            border:'1px solid lightgrey',
                            fontSize: '0.75em',
                        }} 
                        wrapperStyle={{ outline: "none" }}
                        labelFormatter={formatXAxisLabel}
                        formatter={formatReadings}
                    />

                    {props.loggedUnits.map((unit, i) => {
                        if(unit.checked) return <Line yAxisId='left' type="monotone" dataKey={`${unit.name}.${props.plotSpecs.metricType === 'Power (kW)' ? 'kw' : 'current'}`} stroke={`${colors[i % colors.length]}`} strokeWidth={1.5} dot={false} />
                    })}


                    <Bar 
                        yAxisId='right' type="monotone" dataKey={`events.value`} 
                        barSize={100}
                        style={{cursor:'pointer'}}
                        onMouseEnter={(_, index) => props.setEventIndex(index)}
                        onMouseLeave={() => props.setEventIndex(null)}
                        onClick={dataPoint => {
                            props.setAccordionKey('1');
                            props.setSelectedEvents(dataPoint.payload.events.ids);
                        }}
                    >
                        {props.data.map((_, index) => (
                            <Cell key={`cell-${index}`} fill={index === props.eventIndex ? 'rgba(247,230,83,0.4)':'rgba(213,43,0,0.4)'} style={{cursor:'pointer'}}/>
                        ))}
                    </Bar>
                </ComposedChart>
            }
        </ResponsiveContainer>
    );
}

export default Graph;