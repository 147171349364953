import React, { useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import ConstructionIcon from '@mui/icons-material/Construction';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import useAxiosProtect from "../../../../../hooks/useAxiosProtect";
import EventInfoModal from "./EventInfoModal";

const DataPanel = props => {
    const type = 'checkbox';

    const [currentSite, setCurrentSite] = React.useState(window.location.href.split('/').slice(-1));

    const [selectAll, setSelectAll] = useState(true);

    const axiosProtect = useAxiosProtect();
    const [eventData, setEventData] = useState(null);

    const handleChange = e => {
        if(e.target.value == currentSite) return;
        window.history.replaceState({}, '', `/energy-metrics/${e.target.value}`);
        props.resetAuthCheck();
    };

    const toggleAccordionKey = key => {
        props.setAccordionKey(props.accordionKey === key ? null : key);
    }

    const formatEventTime = dateString => {
        const date = new Date(dateString);

        const localDate = new Date(date.getTime());
    
        const day = String(localDate.getDate()).padStart(2, '0');
        const month = localDate.toLocaleString('default', { month: 'long' });
        const year = localDate.getFullYear();
        const hour = String(localDate.getHours()).padStart(2, '0');
        const minute = String(localDate.getMinutes()).padStart(2, '0');
    
        return `${day} ${month} ${year} ${hour}:${minute}`;
    }

    const removeEvent = eventID => {
        props.setEvents(prev => prev.filter(ev => ev.id != eventID));
    }

    return(
        <>
        {
            props.isClient &&
            <Box sx={{ minWidth: 120, marginBottom: '1vh' }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Site</InputLabel>
                    <Select
                        value={currentSite}
                        label="Site"
                        onChange={handleChange}
                    >
                        {
                            props.sites.map((site, i) => (
                                <MenuItem value={site.id.toString()}>{site.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
        }

        {props.eventInfoModalOpen && <EventInfoModal eventInfoModalOpen={props.eventInfoModalOpen} 
            setEventInfoModalOpen={props.setEventInfoModalOpen} eventData={eventData} formatEventTime={formatEventTime} 
            removeEvent={removeEvent} setPlotSpecs={props.setPlotSpecs} toolLoad={props.toolLoad} setData={props.setData} setErrorMessage={props.setErrorMessage}/>}

        <Accordion activeKey={props.accordionKey}>
            <FormControl disabled={props.plotSpecs.activeUtility === 'electricity' && props.plotSpecs.metricType === 'Current (A)'}>
                <FormLabel id="demo-controlled-radio-buttons-group">Aggregate readings as</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={props.plotSpecs.aggregateMethod}
                    onChange={e => {
                        props.toolLoad.current = true;
                        props.specUpdateActive.current = true;

                        props.setPlotSpecs(prev => ({
                            ...prev,
                            ['aggregateMethod']: e.target.value
                        }));
                    }}
                    row
                >
                    <FormControlLabel value="average" control={<Radio />} label="Average" />
                    <FormControlLabel value="sum" control={<Radio />} label="Sum" />
                </RadioGroup>
            </FormControl>
            
            <Accordion.Item eventKey="0">
                <Accordion.Header onClick={() => toggleAccordionKey('0')}>{`Data sets (${props.loggedUnits.length})`}</Accordion.Header>
                <Accordion.Body style={{height:`${props.isClient ? '48vh' : '51vh'}`}}>
                    <Form.Check
                        type={type}
                        checked={selectAll}
                        label='SELECT ALL'
                        disabled={props.loggedUnits?.length === 0}
                        onChange={() => {
                            props.toggleCheckedUnit(null, !selectAll);
                            setSelectAll(!selectAll);
                        }}
                    />

                    {props.loggedUnits.map((unit, i) => (
                        <Form.Check
                            style={{marginLeft:'5%'}}
                            type={type}
                            id={`default-${type}`}
                            label={`${unit.name}${props.plotSpecs.activeUtility === 'gas' ? ` [${unit.gas_name}, ${unit.gas_units === 'm3' ? 'm³' : unit.gas_units}]` : ''}`}
                            checked={unit.checked}
                            onChange={() => {
                                let wasEnabled = props.toggleCheckedUnit(unit.id);
                                if(!wasEnabled) setSelectAll(false);
                                else{
                                    let allSelected = true;

                                    for(let i = 0;i<props.loggedUnits.length;++i){
                                        if(!props.loggedUnits[i].checked){
                                            allSelected = false;
                                            break;
                                        }
                                    }

                                    setSelectAll(allSelected);
                                }
                            }}
                            className='data-checkbox'
                        />
                    ))}
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header onClick={() => toggleAccordionKey('1')}>{`Events (${props.events.length})`}</Accordion.Header>
                <Accordion.Body style={{height:`${props.isClient ? '48vh' : '51vh'}`, overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {
                            props.events.map((event, i) => (
                                <ListItemButton sx={{ bgcolor: props.selectedEvents.includes(event.id) ? 'rgba(0,177,168,0.4)' : 'background.paper' }} onClick={() => {
                                    axiosProtect.get(`/s/sites/get-site-event?eventID=${event.id}`).then(response => {
                                        setEventData(response.data.eventData);
                                        props.setEventInfoModalOpen(true);
                                    }).catch(err => {});
                                }}>
                                    <ListItemAvatar>
                                    <Avatar>
                                        {
                                            event.category === 'leak' ? <InvertColorsIcon/> : 
                                            event.category === 'production' ? <WarehouseIcon/> : 
                                            event.category === 'malfunction' ? <ConstructionIcon/> : 
                                            event.category === 'alert' ? <PriorityHighIcon/> :
                                            <MiscellaneousServicesIcon/>
                                        }
                                    </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={event.title} secondary={formatEventTime(event.event_time_utc)} />
                                </ListItemButton>
                            ))
                        }
                    </List>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>
    );
}

export default DataPanel;