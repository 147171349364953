import React, { useState } from 'react';
import { Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ComposedChart, Line, Legend } from 'recharts';

const TotalSavings = props => {
    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'numeric' });
        const day = date.getDate();
    
        return `${day}/${month}/${year}`;
    };

    const formatYAxisTick = tick => {
        let value = tick;
        if(value >= 1000 && value < 1000000){
            value = Math.round(10 * tick / 1000) / 10;
        }else if(value >= 1000000){
            value = Math.round(10 * tick / 1000 / 1000) / 10;
        }

        return `$${value}${tick < 1000 ? '' : tick < 1000000 ? 'k' : 'M'}`;
    }

    return(
        <ResponsiveContainer height='100%' width='100%'>
            <ComposedChart data={props.savingsData}>
                <XAxis dataKey="time" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} 
                        textAnchor='middle' tickFormatter={formatXAxisTick}/>

                <YAxis yAxisId="left" tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} tickFormatter={formatYAxisTick}
                    label={{value: `Daily financial savings`,
                    position: 'outside', offset: 0, angle: -90, dx: -24, style: { fontSize: '12px', fill: 'rgb(78,79,83)'}}}/>
                
                <YAxis yAxisId="right" orientation='right' tickLine={false} axisLine={false} tick={{ fontSize: '14px', fill: 'rgb(78,79,83)'}} tickFormatter={formatYAxisTick}
                    label={{value: `Total financial savings`,
                    position: 'outside', offset: 0, angle: 90, dx: 24, style: { fontSize: '12px', fill: 'rgb(78,79,83)' }}}
                />
                
                {/* <Tooltip 
                    contentStyle={{
                        backgroundColor: '#F5F5F5',
                        color: '#333',
                        border:'1px solid lightgrey',
                        fontSize: '0.75em',
                    }}
                    labelFormatter={formatXAxisTick}
                    wrapperStyle={{ outline: "none" }}
                    formatter={formatReadings}
                /> */}

                <Legend verticalAlign='top' iconType='line' align='center' iconSize={8} 
                    payload={
                        [
                            {value: 'Savings', type: 'circle', color:'rgb(8,114,182)'},
                            {value: 'Total savings', type: 'circle', color:'rgb(0,177,168)'}
                        ]
                    }
                />

                <CartesianGrid stroke="#f5f5f5"/>
                
                <Bar yAxisId="left" dataKey='totalCostSavings' fill={'rgb(8,114,182)'}  />
                {/* <Line yAxisId="left" type="monotone" dataKey='target' strokeWidth={2} stroke={'rgb(213,43,0)'} dot={false}/> */}
                <Line yAxisId="right" type="monotone" dataKey='runningCostSavings' strokeWidth={2} stroke={'rgb(0,177,168)'} dot={false}/>
                {/* <Line yAxisId="right" type="monotone" dataKey='total_savings' strokeWidth={2} stroke={'rgb(0,177,168)'} dot={false}/> */}
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default TotalSavings;