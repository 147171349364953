import React, { useEffect, useRef, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import PlaceIcon from '@mui/icons-material/Place';
import Co2Icon from '@mui/icons-material/Co2';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import InsightsIcon from '@mui/icons-material/Insights';
import TuneIcon from '@mui/icons-material/Tune';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EngineeringIcon from '@mui/icons-material/Engineering';
import fLogo from '../../media/flogo.png';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import useAuth from '../../hooks/useAuth';
import useAxiosProtect from '../../hooks/useAxiosProtect';
import jwtDecode from 'jwt-decode';
import ThumbLogo from '../../media/logo.png';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpeedIcon from '@mui/icons-material/Speed';

const CanvasDrawer = props => {
    const navigate = useNavigate();
    const logout = useLogout();
    const axiosProtect = useAxiosProtect();

    const signOut = async () => {
        await logout();
        navigate('/')
    }

    var init = useRef(true);
    var disableOnClick = useRef(false); // set true to prevent user from running same onClick before completion
    const {auth, persist} = useAuth();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCarbonOneUser, setIsCarbonOneUser] = useState(false);
    const [isHMartUser, setIsHMartUser] = useState(false);
    const [isASBRUser, setIsASBRUser] = useState(false);
    const [uid, setUid] = useState('');
    const [isTestClient, setIsTestClient] = useState(false);

    useEffect(() => {
        if(init.current){
            init.current = false;
            
            if(auth?.accessToken){
                let tokenObj = jwtDecode(auth.accessToken);
                setIsAdmin(tokenObj.role === process.env.REACT_APP_ES_ADMIN);
                setIsCarbonOneUser(tokenObj.role === process.env.REACT_APP_CARBON_ONE_USER);
                setIsHMartUser(tokenObj.role === process.env.REACT_APP_HMART_USER);
                setIsASBRUser(tokenObj.role === process.env.REACT_APP_BLUE_ROOF_CLIENT);
                setIsTestClient(tokenObj.email === "placeholder")
                setUid(tokenObj.id);
            }
        }
    }, []);

    return(
        <>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title><img id='drawer-nav-img' src={ThumbLogo} style={{height:'4rem', widht:'auto', cursor:'pointer', borderRadius:'50%', padding:'0.5rem'}} onMouseEnter={() => {
                    document.getElementById('drawer-nav-img').style.backgroundColor = 'rgba(0,0,0,0.1)';
                }} onMouseLeave={() => {
                    document.getElementById('drawer-nav-img').style.backgroundColor = 'rgba(0,0,0,0)';
                }} onClick={() => {
                    window.location.href = '/dashboard';
                }}/></Offcanvas.Title>
            </Offcanvas.Header>

            <Divider style={{backgroundColor:'rgb(226,227,232)'}}/>

            <Offcanvas.Body style={{overflowY:'scroll'}}>
                <div style={{position:'relative', height:'100%', width:'100%'}}>
                    <List>
                        {isAdmin ? <>
                            <ListItem disablePadding onClick={() => window.location.href='/pricing'}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <RequestQuoteIcon style={{color:'rgb(8,114,182)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Pricing calculator" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => window.location.href='/ghg-factors'}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LightbulbIcon style={{color:'rgb(8,114,182)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="GHG Factors" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => window.location.href='/projects'}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <AccountTreeIcon style={{color:'rgb(8,114,182)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Project Management" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => window.location.href='/blueroof'}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <WaterDamageIcon style={{color:'rgb(8,114,182)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Blue Roof" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => window.location.href='/customer-management'}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ManageAccountsIcon style={{color:'rgb(8,114,182)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="Customer Management" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding onClick={() => window.location.href='/hmart'}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <HMobiledataIcon style={{color:'rgb(8,114,182)'}}/>
                                    </ListItemIcon>
                                    <ListItemText primary="H-Mart System" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>
                        </> : (isCarbonOneUser || isHMartUser || isASBRUser) ?
                        <>

                        </> :
                        <>
                            <ListItem disablePadding onClick={() => {
                                window.location.href = '/opportunities';
                            }}>
                                <ListItemButton>
                                <ListItemIcon>
                                    <LightbulbIcon style={{color:'rgb(8,114,182)'}}/>
                                </ListItemIcon>
                                <ListItemText primary='Opportunities' style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding onClick={() => {
                                if(disableOnClick.current) return;

                                disableOnClick.current = true;
                                
                                axiosProtect.get(`/auth/query/list-client-site-access?uid=${uid}`).then(response => {
                                    let siteID = response.data.sites[0].id;
                                    window.location.href = `/energy-metrics/${siteID}`;

                                    disableOnClick.current = false;
                                }).catch(err => {
                                    disableOnClick.current = false;
                                });
                            }}>
                                <ListItemButton>
                                <ListItemIcon>
                                    <EnergySavingsLeafIcon style={{color:'rgb(8,114,182)'}}/>
                                </ListItemIcon>
                                <ListItemText primary="Utility data" style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>

                            {/* <ListItem disablePadding>
                                <ListItemButton disabled={!isTestClient}>
                                <ListItemIcon>
                                    <PrecisionManufacturingIcon style={{color:'rgb(8,114,182)'}}/>
                                </ListItemIcon>
                                <ListItemText primary={`Production metrics${isTestClient ? '' : ' (Coming soon)'}`} style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem> */}



                            <ListItem disablePadding /*onClick={() => {window.location.href='/emissions'}}*/>
                                <Accordion disableGutters elevation={0} style={{width:'100%'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        disablePadding
                                    >
                                        <ListItemIcon>
                                            <Co2Icon style={{color:'rgb(8,114,182)'}}/>
                                        </ListItemIcon>

                                        <ListItemText primary={`Emissions`} style={{color:'rgb(78,79,83)'}}/>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ListItem disablePadding onClick={() => {window.location.href='/emissions-savings'}}>
                                            <ListItemButton style={{width:'100%'}}>
                                                <ListItemIcon>
                                                    <SpeedIcon style={{color:'rgb(8,114,182)'}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={`Savings`} style={{color:'rgb(78,79,83)'}}/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding onClick={() => {window.location.href='/emissions-trends'}}>
                                            <ListItemButton style={{width:'100%'}}>
                                                <ListItemIcon>
                                                    <InsightsIcon style={{color:'rgb(8,114,182)'}}/>
                                                </ListItemIcon>
                                                <ListItemText primary={`Trends`} style={{color:'rgb(78,79,83)'}}/>
                                            </ListItemButton>
                                        </ListItem>
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>

                            {/* <ListItem disablePadding onClick={() => {window.location.href='/infographics/guest'}}>
                                <ListItemButton>
                                <ListItemIcon>
                                    <InsightsIcon style={{color:'rgb(8,114,182)'}}/>
                                </ListItemIcon>
                            <ListItemText primary={`Infographics${isTestClient ? '' : ' (Coming soon)'}`} style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem> */}

                            <ListItem disablePadding onClick={() => {window.location.href='/activity-log'}}>
                                {/* <ListItem disablePadding onClick={() => {if(!isTestClient) return; window.location.href='/emissions'}}> */}
                                <ListItemButton>
                                {/* <ListItemButton disabled={!isTestClient}> */}
                                <ListItemIcon>
                                    <AssignmentIcon style={{color:'rgb(8,114,182)'}}/>
                                </ListItemIcon>
                                <ListItemText primary={`Activity log`} style={{color:'rgb(78,79,83)'}}/>
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding onClick={() => {/*window.location.href='/maintenance'*/}}>
                                <ListItemButton disabled>
                                {/* <ListItemButton disabled={!isTestClient}> */}
                                <ListItemIcon>
                                    <EngineeringIcon style={{color:'rgb(8,114,182)'}}/>
                                </ListItemIcon>
                                <ListItemText primary='Maintenance portal (Coming soon)' style={{color:'rgb(78,79,83)'}}/>
                                {/* <ListItemText primary={`Sites${isTestClient ? '' : ' (Coming soon)'}`} style={{color:'rgb(78,79,83)'}}/> */}
                                </ListItemButton>
                            </ListItem>
                        </>}

                        <Divider style={{backgroundColor:'rgb(226,227,232)'}}/>

                        <ListItem disablePadding onClick={() => window.location.href='/profile'}>
                            <ListItemButton>
                            <ListItemIcon>
                                <PersonIcon style={{color:'rgb(8,114,182)'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" style={{color:'rgb(78,79,83)'}}/>
                            </ListItemButton>
                        </ListItem>

                        {/* <ListItem disablePadding>
                            <ListItemButton disabled={!isTestClient}>
                            <ListItemIcon>
                                <TuneIcon style={{color:'rgb(8,114,182)'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Settings" style={{color:'rgb(78,79,83)'}}/>
                            </ListItemButton>
                        </ListItem> */}

                        <ListItem disablePadding onClick={() => {
                            signOut();
                        }}>
                            <ListItemButton>
                            <ListItemIcon>
                                <LogoutIcon style={{color:'rgb(8,114,182)'}}/>
                            </ListItemIcon>
                            <ListItemText primary="Log out" style={{color:'rgb(78,79,83)'}}/>
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <img src={fLogo} style={{
                        position:'absolute', top:'0', left:'0',bottom:'0',right:'0',width:'auto', height:'auto',maxWidth:'100%',maxHeight:'100%',margin:'auto auto 0'
                        }}/>
                </div>
            </Offcanvas.Body>
        </>
    );
}

export default CanvasDrawer;