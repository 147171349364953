import React, { useState, useEffect, useRef } from "react";
import DataPanel from "./components/DataPanel";
import useAxiosProtect from "../../../../hooks/useAxiosProtect";
import Graph from "./components/Graph";
import colors from "../../../../example_data/colors";
import './styling/containerStyle.css';
import Toolbar from "./components/Toolbar";
import CustomTimeframeModal from "./components/CustomTimeframeModal";
import EventsModal from "./components/EventsModal";

const UtilityGraphContainer = props => {
    // top level vars
    const axiosProtect = useAxiosProtect();
    const siteID = props.siteID === undefined ? window.location.href.split('/')[window.location.href.split('/').length - 1] : props.siteID;
    var init = useRef(true);
    var specUpdateActive = useRef(false);

    // Graph vars
    const [data, setData] = useState([]);
    const [loggedUnits, setLoggedUnits] = useState([]);
    const [maxYValue, setMaxYValue] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    var keepCheckedStates = useRef(false); // set to true if change made to plotSpecs, but not if utility type changed

    // Toolbar vars
    const [plotSpecs, setPlotSpecs] = useState({
        activeUtility: '',
        timeframe: '',
        metricType: '',
        metricOptions: [
            ''
        ],
        resolution: '',
        customStart: '',
        customEnd: '',
        aggregateMethod: 'average'
    });
    var toolLoad = useRef(false);
    const [scopeData, setScopeData] = useState({
        hasWater: false,
        hasElec: false,
        hasGas: false
    });

    //data panel
    const [accordionKey, setAccordionKey] = useState('0');

    const [customTimeframeModalOpen, setCustomTimeframeModalOpen] = useState(true);
    const [eventsModalOpen, setEventsModalOpen] = useState(false);
    const [eventInfoModalOpen, setEventInfoModalOpen] = useState(false);

    const [eventIndex, setEventIndex] = useState(null);
    const [events, setEvents] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(() => {
        if(init.current){
            init.current = false;

            axiosProtect.get(`/s/sites/get-site-fields?fields=%5B%22has_water%22,%22has_elec%22,%22has_gas%22%5D&siteID=${siteID}`).then(response => {
                if(response.status !== 200) return;

                try{
                    setScopeData(prev => ({
                        ...prev,
                        ['hasWater']: response.data.siteData.has_water,
                        ['hasElec']: response.data.siteData.has_elec,
                        ['hasGas']: response.data.siteData.has_gas
                    }));

                    let utilityType;
                    if(['water', 'electricity', 'gas'].includes(window.name)){
                        utilityType = window.name;

                        setPlotSpecs(prev => ({
                            ...prev,
                            [`activeUtility`]: utilityType,
                            ['timeframe']: '4h',
                            // ['metricType']: 'Flow rate (LPM)',
                            // ['metricOptions']: ['Flow rate (LPM)'],
                            ['metricType']: window.name === 'water' ? 'Water usage (L)' : window.name === 'electricity' ? 'Current (A)' : 'Gas usage (m3)',
                            ['metricOptions']: [window.name === 'water' ? 'Water usage (L)' : window.name === 'elelctricity' ? 'Current (A)' : 'Gas usage (m3)'],
                            ['resolution']: '1m'
                        }));
                    }
                    else if(response.data.siteData.has_water){
                        utilityType = 'water';

                        setPlotSpecs(prev => ({
                            ...prev,
                            [`activeUtility`]: utilityType,
                            ['timeframe']: '4h',
                            // ['metricType']: 'Flow rate (LPM)',
                            // ['metricOptions']: ['Flow rate (LPM)'],
                            ['metricType']: 'Water usage (L)',
                            ['metricOptions']: ['Water usage (L)'],
                            ['resolution']: '1m'
                        }));
                    }
                    else if(response.data.siteData.has_elec){
                        utilityType = 'electricity';

                        setPlotSpecs(prev => ({
                            ...prev,
                            [`activeUtility`]: utilityType,
                            ['timeframe']: '4h',
                            ['metricType']: 'Current (A)',
                            ['metricOptions']: ['Current (A)','Power (kW)'],
                            ['resolution']: '1m',
                            ['aggregateMethod']: 'average'
                        }));

                        populateGraphData('electricity');

                    }else if(response.data.siteData.has_gas){
                        utilityType = 'gas';

                        setPlotSpecs(prev => ({
                            ...prev,
                            [`activeUtility`]: utilityType,
                            ['timeframe']: '4h',
                            ['metricType']: 'Gas usage (m3)',
                            ['metricOptions']: ['Gas usage (m3)','Power (kW)'],
                            ['resolution']: '1m'
                        }));

                        populateGraphData('gas');
                    }

                    window.name = '';

                    populateGraphData(utilityType);

                }catch(err){
                    window.name = '';
                }
            }).catch(err => {console.log(err)});
        }
    }, []);

    useEffect(() => {
        setCustomTimeframeModalOpen(false);
        setEventInfoModalOpen(false);
        if(!specUpdateActive.current) return;

        updateYAxisMax(loggedUnits);

        if(toolLoad.current){
            toolLoad.current = false;
            populateGraphData();
        }

    }, [plotSpecs]);

    const populateGraphData = async (utilityType=null) => {
        // let resolution = '1m';
        // if(plotSpecs.timeframe.endsWith('d') && plotSpecs.timeframe !== '1d') resolution = plotSpecs.timeframe.length > 2 ? '12h' : '1h';
        // console.log(plotSpecs)
        let resolution = plotSpecs.resolution === '' ? '1m' : plotSpecs.resolution;
        let timeframe = plotSpecs.timeframe === '' ? '4h' : plotSpecs.timeframe;

        // let payloadString = isDefault ? `/r/raw-data/dataset?id=${siteID}&utility=${utilityType ? utilityType : plotSpecs.activeUtility}` : 
        // `r/raw-data/dataset?id=${siteID}&resolution=${resolution}&start=${plotSpecs.timeframe}&utility=${utilityType ? utilityType : plotSpecs.activeUtility}`;
        let payloadString = plotSpecs.timeframe === 'custom' ? `r/raw-data/dataset?id=${siteID}&resolution=${resolution}&start=${plotSpecs.customStart}&end=${plotSpecs.customEnd}&utility=${utilityType ? utilityType : plotSpecs.activeUtility}&isCustom=true&method=${plotSpecs.aggregateMethod}` : 
        `r/raw-data/dataset?id=${siteID}&resolution=${resolution}&start=${timeframe}&utility=${utilityType ? utilityType : plotSpecs.activeUtility}&method=${plotSpecs.aggregateMethod}`

        try{
            let response = await axiosProtect.get(payloadString);
        
            if(response.status === 204){
                setErrorMessage("Data for requested utility is not in this site's scope.");
                specUpdateActive.current = true;
                return;

            }else if(response.status !== 200){
                setErrorMessage("Something went wrong. Please try again later or contact support.");
                return;

            }else setErrorMessage('');

            if(!keepCheckedStates.current){
                for(let i = 0;i<response.data.devices.length;++i){
                    response.data.devices[i].checked = true;
                }
            }else{
                let renderedUnits = {};
                for(let i = 0;i<loggedUnits.length;++i){
                    renderedUnits[loggedUnits[i].id] = loggedUnits[i].checked; 
                }

                for(let i = 0;i<response.data.devices.length;++i){
                    response.data.devices[i].checked = renderedUnits[response.data.devices[i].id];
                }
            }

            setLoggedUnits(response.data.devices);

            addPowerMetric(response.data.data);

            for(let i = 0;i<response.data.data.length;++i){
                response.data.data[0].events = {value: 0, ids: []}
            }

            setData(response.data.data);
            setEvents(response.data.events);

            updateYAxisMax(response.data.devices, response.data.data);

            specUpdateActive.current = true;

        }catch(err){
            setErrorMessage("Something went wrong. Please try again later or contact support.");
        }

        keepCheckedStates.current = false;
    }

    const updateYAxisMax = (devices, dataArray=null) => {
        let activeDevices = {};
        for(let i = 0;i<devices.length;++i){
            if(devices[i].checked){
                activeDevices[devices[i].name] = 0;
            }
        }

        let maxValue = -1;
        if(data == null && dataArray == null){
            setMaxYValue(1);
            return;
        }

        let activeKey = plotSpecs.metricType === 'Power (kW)' ? 'kw' : 'current';

        if(dataArray == null){
            for(let i = 0;i<data.length;++i){
                for(let key in data[i]){
                    if(activeDevices.hasOwnProperty(key)) maxValue = Math.max(maxValue, parseFloat(data[i][key][activeKey]))
                }
            }

        }else{
            for(let i = 0;i<dataArray.length;++i){
                for(let key in dataArray[i]){
                    if(activeDevices.hasOwnProperty(key)) maxValue = Math.max(maxValue, parseFloat(dataArray[i][key][activeKey]))
                }
            }
        }

        maxValue = maxValue === -1 ? 1 : Math.max(1, parseInt(1.05 * maxValue) + 1);
        maxValue = Math.ceil(maxValue / 5) * 5 
        
        setMaxYValue(maxValue);
    }

    const toggleCheckedUnit = (id, enableAll=null) => {
        let temp = [...loggedUnits];
        let lastChangedValue = null;

        if(enableAll != null){
            //use to enable or disable all units, not toggle specific unit, else leave param 2 null
            for(let i = 0;i<temp.length;++i){
                temp[i].checked = enableAll;
            }

            setLoggedUnits(temp);
            lastChangedValue = enableAll;

        }else{
            let temp = [...loggedUnits];

            for(let i = 0;i<temp.length;++i){
                if(temp[i].id === id){
                    temp[i].checked = !temp[i].checked;
                    lastChangedValue = temp[i].checked;
                    setLoggedUnits(temp);
                    break;
                }
            }
        }

        updateYAxisMax(temp);
        return lastChangedValue;
    }

    const computeMinimumResolution = timeframe => {
        if(timeframe === '1h') return '1m';
        else if(timeframe === '4h') return '1m';
        else if(timeframe === '12h') return '5m';
        else if(timeframe === '1d') return '5m';
        else if(timeframe === '7d') return '1h';
        else if(timeframe === '30d') return '1h';
        else if(timeframe === '365d') return '1d';
        else if(timeframe === 'custom') return '';
    }

    const updatePlotSpecs = (e, field) => {
        if(field !== 'activeUtility'){
            keepCheckedStates.current = true;

            // if(field !== 'metricType'){
            //     setData([]);
            //     setErrorMessage('')
            //     toolLoad.current = true;
            // }
            setData([]);
            setErrorMessage('')
            toolLoad.current = true;

            if(field === 'timeframe'){
                setPlotSpecs(prev => ({
                    ...prev,
                    [`${field}`]: e.target.value,
                    resolution: computeMinimumResolution(e.target.value)
                }));
            }else if(field === 'metricType' && e.target.value === 'Current (A)'){
                setPlotSpecs(prev => ({
                    ...prev,
                    [`${field}`]: e.target.value,
                    ['aggregateMethod']: 'average'
                }));
            }else{
                setPlotSpecs(prev => ({
                    ...prev,
                    [`${field}`]: e.target.value
                }));
            }
        }else{
            let options = [];

            if(e.target.value === 'water'){
                options = ['Water usage (L)'];
                // options = ['Flow rate (LPM)'];
            }else if(e.target.value === 'electricity'){
                options = ['Current (A)','Power (kW)']
            }else if(e.target.value === 'gas'){
                options = ['Gas usage (m3)']
            }

            setData([]);
            setErrorMessage('');
            toolLoad.current = true;

            setPlotSpecs(prev => ({
                ...prev,
                [`${field}`]: e.target.value,
                ['metricType']: options[0],
                ['metricOptions']: options,
                ['aggregateMethod']: e.target.value === 'electricity' ? 'average' : plotSpecs.aggregateMethod
            }));
        }
    };

    const addPowerMetric = readings => {
        for(let i = 0;i<readings.length;++i){
            for(let key in readings[i]){
                if(key === 'time') continue;
                readings[i][key].power_kwh = readings[i][key].current * readings[i][key].power_factor * readings[i][key].voltage * Math.sqrt(3) / 60 / 1000;
            }
        }
    }

    const openModal = () => {
        setCustomTimeframeModalOpen(true);
    }

    return(
        <>
        {customTimeframeModalOpen && <CustomTimeframeModal customTimeframeModalOpen={customTimeframeModalOpen} 
            setCustomTimeframeModalOpen={setCustomTimeframeModalOpen} setPlotSpecs={setPlotSpecs} toolLoad={toolLoad}
            setData={setData} setErrorMessage={setErrorMessage}
            />
        }

        {eventsModalOpen && <EventsModal eventsModalOpen={eventsModalOpen} setEventsModalOpen={setEventsModalOpen} events={events} setEvents={setEvents}/>}

        <div className="chart-container">
            <div className="chart-comp-panel">
                <div className="chart-comp-body">
                    <DataPanel 
                        loggedUnits={loggedUnits} toggleCheckedUnit={toggleCheckedUnit} plotSpecs={plotSpecs}
                        isClient={props.isClient} sites={props.sites} resetAuthCheck={props.resetAuthCheck} 
                        accordionKey={accordionKey} setAccordionKey={setAccordionKey} events={events}
                        setEvents={setEvents} selectedEvents={selectedEvents} setPlotSpecs={setPlotSpecs}
                        toolLoad={toolLoad} setData={setData} setErrorMessage={setErrorMessage}
                        eventInfoModalOpen={eventInfoModalOpen} setEventInfoModalOpen={setEventInfoModalOpen}
                        specUpdateActive={specUpdateActive}
                    />
                </div>
            </div>

            <div className='chart-divider'></div>

            <div className="chart-graph-container">
                <Toolbar plotSpecs={plotSpecs} updatePlotSpecs={updatePlotSpecs} scopeData={scopeData} data={data} openModal={openModal} setEventsModalOpen={setEventsModalOpen}/>

                <div className='chart-graph-body'>
                    <div className="chart-graph-graph">
                        <Graph 
                            data={data} loggedUnits={loggedUnits} maxYValue={maxYValue} plotSpecs={plotSpecs} 
                            errorMessage={errorMessage} eventIndex={eventIndex} setEventIndex={setEventIndex} 
                            accordionKey={accordionKey} setAccordionKey={setAccordionKey} setSelectedEvents={setSelectedEvents}
                        />
                    </div>

                    <div className="chart-graph-legend">
                        <ul>
                            {loggedUnits.map((unit, i) => {
                                if(unit.checked) return <li className='chart-legend-element' style={{color: `${colors[i % colors.length]}`}}><span>{unit.name}</span></li>
                            })}
                        </ul>
                    </div>
                </div> 
            </div>
        </div>
        </>
    );
}

export default UtilityGraphContainer;