import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import useAxiosProtect from '../../../../hooks/useAxiosProtect';
import NewUnitCostForm from './NewUnitCostForm';
import dayjs from 'dayjs';

const SiteConfigPage = props => {
    const intRegex = /^(|\d+)$/;
    const floatRegex = /^(-?\d*\.?\d*)?$/;

    const axiosProtect = useAxiosProtect();

    const [formData, setFormData] = useState({
        name: {value: '', error: ''},
        address: {value: '', error: ''},
        sqft: {value: '', error: ''},
        longitude: {value: '', error: ''},
        latitude: {value: '', error: ''},
        unitCosts: {
            water: {value: '', error: ''},
            electricity: {value: '', error: ''},
            pf: {value: '', error: ''},
            gas: {value: '', error: ''}
        },
        ghgFactors: {
            custom: false,
            selectedJurisdiction: {value: null, error: ''},
            water: {value: '', error: ''},
            electricity: {value: '', error: ''},
            gas: {value: '', error: ''}
        },
    });

    const [updatedUnitCosts, setUpdatedUnitCosts] = useState({
        time: {value: null, error: ''},
        water: {value: '', error: ''},
        electricity: {value: '', error: ''},
        gas: {value: '', error: ''},
    });

    const [jurisdictions, setJurisdictions] = useState([]);

    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    var init = useRef(true);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        let siteID = window.location.href.split('/').slice(-1)[0];

        axiosProtect.get(`/s/sites/get-site-config?site_id=${siteID}`).then(response => {
            let formCopy = {...formData};
            let site = response.data.siteData;

            formCopy.name.value = site.name;
            formCopy.address.value = site.address;
            formCopy.sqft.value = site.sqft;
            formCopy.longitude.value = site.longitude;
            formCopy.latitude.value = site.latitude;

            formCopy.unitCosts.water.value = site.water_unit_cost;
            formCopy.unitCosts.electricity.value = site.elec_unit_cost;
            formCopy.unitCosts.pf.value = site.power_factor;
            formCopy.unitCosts.gas.value = site.gas_unit_cost;

            formCopy.ghgFactors.custom = site.ghg_factor_id == null;
            formCopy.ghgFactors.water.value = site.water_ghg_factor_kg_m3;
            formCopy.ghgFactors.electricity.value = site.elec_ghg_factor_kg_kwh;
            formCopy.ghgFactors.gas.value = site.gas_ghg_factor_kg_btu;

            if(site.ghg_factor_id != null){
                for(let i = 0;i<response.data.jurisdictions.length;++i){
                    if(response.data.jurisdictions[i].values.id === site.ghg_factor_id){
                        formCopy.ghgFactors.selectedJurisdiction.value = {...response.data.jurisdictions[i]};
                        formCopy.ghgFactors.water.value = response.data.jurisdictions[i].values.water_kg_co2_m3;
                        formCopy.ghgFactors.electricity.value = response.data.jurisdictions[i].values.elec_kg_co2_kwh;
                        formCopy.ghgFactors.gas.value = response.data.jurisdictions[i].values.gas_kg_co2_btu;
                        break;
                    }
                }
            }

            let updatedCosts = {...updatedUnitCosts};
            updatedCosts.time.value = site.updated_unit_cost_time ? dayjs(site.updated_unit_cost_time * 1000) : null;
            updatedCosts.water.value = site.updated_water_cost_m3 ? site.updated_water_cost_m3 : '';
            updatedCosts.electricity.value = site.updated_elec_cost_kwh ? site.updated_elec_cost_kwh : '';
            updatedCosts.gas.value = site.updated_gas_cost_m3 ? site.updated_gas_cost_m3 : '';

            setFormData({...formCopy});
            setUpdatedUnitCosts({...updatedCosts});
            setJurisdictions([...response.data.jurisdictions]);

        }).catch(error => {});

    }, []);

    const updateFormData = (value, field, subField=null) => {
        if(subField != null || ['latitude', 'longitude'].includes(field)){
            if(!floatRegex.test(value)) return;
        }else if(field === 'sqft'){
            if(!intRegex.test(value)) return;
        }

        if(subField){
            setFormData(prev => ({
                ...prev,
                [`${field}`]: {
                    ...prev[`${field}`],
                    [`${subField}`]: {
                        ...prev[`${field}`][`${subField}`],
                        value: value
                    }
                }
            }));
        }else{
            setFormData(prev => ({
                ...prev,
                [`${field}`]: {
                    value: value,
                    error: formData[`${field}`].error
                }
            }));
        }
    }

    const validateFormData = () => {
        let isValid = true;
        let formCopy = {...formData};

        if(formCopy.name.value.trim() === ''){
            formCopy.name.error = 'Required';
            isValid = false;
        }else formCopy.name.error = '';

        if(formCopy.address.value.trim() === ''){
            formCopy.address.error = 'Required';
            isValid = false;
        }else formCopy.address.error = '';

        if(formCopy.sqft.value.toString().trim() === ''){
            formCopy.sqft.error = 'Required';
            isValid = false;
        }else if(isNaN(parseInt(formCopy.sqft.value))){
            formCopy.sqft.error = 'Invalid input';
            isValid = false;
        }else if(parseInt(formCopy.sqft.value) <= 0){
            formCopy.sqft.error = 'Must be greater than 0';
            isValid = false;
        }else formCopy.sqft.error = '';

        if(formCopy.latitude.value.toString().trim() === ''){
            formCopy.latitude.error = 'Required';
            isValid = false;
        }else if(isNaN(parseFloat(formCopy.latitude.value))){
            formCopy.latitude.error = 'Invalid input';
            isValid = false;
        }else if(parseFloat(formCopy.latitude.value) > 90 || parseFloat(formCopy.latitude.value) < -90){
            formCopy.latitude.error = 'Must be between -90 and 90';
            isValid = false;
        }else formCopy.latitude.error = '';

        if(formCopy.longitude.value.toString().trim() === ''){
            formCopy.longitude.error = 'Required';
            isValid = false;
        }else if(isNaN(parseFloat(formCopy.longitude.value))){
            formCopy.longitude.error = 'Invalid input';
            isValid = false;
        }else if(parseFloat(formCopy.longitude.value) > 180 || parseFloat(formCopy.longitude.value) < -180){
            formCopy.longitude.error = 'Must be between -180 and 180';
            isValid = false;
        }else formCopy.longitude.error = '';

        if(formCopy.unitCosts.water.value.toString().trim() === ''){
            formCopy.unitCosts.water.error = 'Required';
            isValid = false;
        }else if(isNaN(parseFloat(formCopy.unitCosts.water.value))){
            formCopy.unitCosts.water.error = 'Invalid input';
            isValid = false;
        }else if(parseFloat(formCopy.unitCosts.water.value) <= 0){
            formCopy.unitCosts.water.error = 'Must be greater than 0';
            isValid = false;
        }else formCopy.unitCosts.water.error = '';

        if(formCopy.unitCosts.electricity.value.toString().trim() === ''){
            formCopy.unitCosts.electricity.error = 'Required';
            isValid = false;
        }else if(isNaN(parseFloat(formCopy.unitCosts.electricity.value))){
            formCopy.unitCosts.electricity.error = 'Invalid input';
            isValid = false;
        }else if(parseFloat(formCopy.unitCosts.electricity.value) <= 0){
            formCopy.unitCosts.electricity.error = 'Must be greater than 0';
            isValid = false;
        }else formCopy.unitCosts.electricity.error = '';

        if(formCopy.unitCosts.pf.value.toString().trim() === ''){
            formCopy.unitCosts.pf.error = 'Required';
            isValid = false;
        }else if(isNaN(parseFloat(formCopy.unitCosts.pf.value))){
            formCopy.unitCosts.pf.error = 'Invalid input';
            isValid = false;
        }else if(parseFloat(formCopy.unitCosts.pf.value) <= 0 || parseFloat(formCopy.unitCosts.pf.value) > 1){
            formCopy.unitCosts.pf.error = 'Valid power factor range (0, 1]';
            isValid = false;
        }else formCopy.unitCosts.pf.error = '';

        if(formCopy.unitCosts.gas.value.toString().trim() === ''){
            formCopy.unitCosts.gas.error = 'Required';
            isValid = false;
        }else if(isNaN(parseFloat(formCopy.unitCosts.gas.value))){
            formCopy.unitCosts.gas.error = 'Invalid input';
            isValid = false;
        }else if(parseFloat(formCopy.unitCosts.gas.value) <= 0){
            formCopy.unitCosts.gas.error = 'Must be greater than 0';
            isValid = false;
        }else formCopy.unitCosts.gas.error = '';

        if(formCopy.ghgFactors.custom){
            if(formCopy.ghgFactors.water.value.toString().trim() === ''){
                formCopy.ghgFactors.water.error = 'Required';
                isValid = false;
            }else if(isNaN(parseFloat(formCopy.ghgFactors.water.value))){
                formCopy.ghgFactors.water.error = 'Invalid input';
                isValid = false;
            }else if(parseFloat(formCopy.ghgFactors.water.value) <= 0){
                formCopy.ghgFactors.water.error = 'Must be greater than 0';
                isValid = false;
            }else formCopy.ghgFactors.water.error = '';
    
            if(formCopy.ghgFactors.electricity.value.toString().trim() === ''){
                formCopy.ghgFactors.electricity.error = 'Required';
                isValid = false;
            }else if(isNaN(parseFloat(formCopy.ghgFactors.electricity.value))){
                formCopy.ghgFactors.electricity.error = 'Invalid input';
                isValid = false;
            }else if(parseFloat(formCopy.ghgFactors.electricity.value) <= 0){
                formCopy.ghgFactors.electricity.error = 'Must be greater than 0';
                isValid = false;
            }else formCopy.ghgFactors.electricity.error = '';
    
            if(formCopy.ghgFactors.gas.value.toString().trim() === ''){
                formCopy.ghgFactors.gas.error = 'Required';
                isValid = false;
            }else if(isNaN(parseFloat(formCopy.ghgFactors.gas.value))){
                formCopy.ghgFactors.gas.error = 'Invalid input';
                isValid = false;
            }else if(parseFloat(formCopy.ghgFactors.gas.value) <= 0){
                formCopy.ghgFactors.gas.error = 'Must be greater than 0';
                isValid = false;
            }else formCopy.ghgFactors.gas.error = '';

            formCopy.ghgFactors.selectedJurisdiction.error = '';

        }else{
            if(formCopy.ghgFactors.selectedJurisdiction.value == null){
                formCopy.ghgFactors.selectedJurisdiction.error = 'Required';
                isValid = false;
            }else formCopy.ghgFactors.selectedJurisdiction.error = '';

            formCopy.ghgFactors.water.error = '';
            formCopy.ghgFactors.electricity.error = '';
            formCopy.ghgFactors.gas.error = '';
        }

        setFormData(formCopy);
        return isValid;
    }

    const saveChanges = () => {
        if(!validateFormData()){
            setShowFailure(true);

            setTimeout(() => {
                setShowFailure(false);
            }, 3250);
        }

        let siteID = window.location.href.split('/').slice(-1)[0];

        let payload = {
            siteID: parseInt(siteID),
            name: formData.name.value.trim(),
            address: formData.address.value.trim(),
            sqft: parseInt(formData.sqft.value),
            latitude: parseFloat(formData.latitude.value),
            longitude: parseFloat(formData.longitude.value),
            //unit costs
            water_unit_cost: parseFloat(formData.unitCosts.water.value),
            elec_unit_cost: parseFloat(formData.unitCosts.electricity.value),
            power_factor: parseFloat(formData.unitCosts.pf.value),
            gas_unit_cost: parseFloat(formData.unitCosts.gas.value),
        };

        //ghg factors
        if(formData.ghgFactors.custom){
            payload.water_ghg_factor_kg_m3 = parseFloat(formData.ghgFactors.water.value);
            payload.elec_ghg_factor_kg_kwh = parseFloat(formData.ghgFactors.electricity.value);
            payload.gas_ghg_factor_kg_btu = parseFloat(formData.ghgFactors.gas.value);
        }else{
            payload.ghg_factor_id = parseInt(formData.ghgFactors.selectedJurisdiction.value.values.id);
        }

        axiosProtect.put('/s/sites/update-site-config', payload).then(response => {
            setShowSuccess(true);

            setTimeout(() => {
                setShowSuccess(false);
            }, 3250);
        }).catch(error => {
            setShowFailure(true);

            setTimeout(() => {
                setShowFailure(false);
            }, 3250);
        });
    }

    const clearUpcomingUnitCosts = async () => {
        let siteID = window.location.href.split('/').slice(-1)[0];
        axiosProtect.put(`/s/sites/clear-upcoming-costs?siteID=${siteID}`).then(response => {
            
        }).catch(error => {});
    }

    const saveUpcomingUnitCosts = async params => {
        let siteID = window.location.href.split('/').slice(-1)[0];

        let payload = {
            siteID: parseInt(siteID),
            time: params.time.value.startOf('day').unix(),
            waterCost: parseFloat(params.water.value),
            electricityCost: parseFloat(params.electricity.value),
            gasCost: parseFloat(params.gas.value),
        };
        
        axiosProtect.put(`/s/sites/update-upcoming-costs`, payload).then(response => {
            setUpdatedUnitCosts({
                time: { ...params.time },
                water: { ...params.water },
                electricity: { ...params.electricity },
                gas: { ...params.gas }
            });

            return true;
        }).catch(error => {});
    }

    return (
        <div>
            {
                (showSuccess || showFailure) && 
                <div style={{
                    width:'50%', 
                    height:'10vh', 
                    borderRadius:'1vh',
                    margin:'auto auto 2vh auto', 
                    backgroundColor: showSuccess ? 'rgba(0,177,168,0.2)' : 'rgba(213,43,0,0.2)', 
                    display:'flex', 
                    justifyContent:'center', 
                    alignItems:'center'
                }}>
                    <h4 style={{margin:'0', fontWeight:'bold', color: showSuccess ? 'rgb(0,177,168)' : 'rgb(213,43,0)'}}>
                        {showSuccess ? 'Changes saved' : 'Could not save changes'}
                    </h4>
                </div>
            }

            <div style={{display:'flex', flexDirection:'row'}}>
                <h4>Properties</h4>

                <div style={{flex:'1'}}></div>

                <Button style={{backgroundColor:'rgba(8,114,182, 0.1)', width:'10vw', minWidth:'fit-content'}} onClick={() => saveChanges()}>Save</Button>
            </div>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label='Name'
                id='site-modal-name'
                value={formData.name.value}
                onChange={e => updateFormData(e.target.value, 'name')}
                error={formData.name.error !== ''}
                helperText={formData.name.error}
            />

            <br/>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label='Address'
                id='site-modal-name'
                value={formData.address.value}
                onChange={e => updateFormData(e.target.value, 'address')}
                error={formData.address.error !== ''}
                helperText={formData.address.error}
            />

            <br/>
            
            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label='Area (sqft)'
                id='site-modal-name'
                value={formData.sqft.value}
                onChange={e => updateFormData(e.target.value, 'sqft')}
                error={formData.sqft.error !== ''}
                helperText={formData.sqft.error}
            />

            <div style={{display:'flex', flexDirection:'row'}}>
                <TextField
                    style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                    autoComplete='off'
                    label='Longitude'
                    id='site-modal-name'
                    value={formData.longitude.value}
                    onChange={e => updateFormData(e.target.value, 'longitude')}
                    error={formData.longitude.error !== ''}
                    helperText={formData.longitude.error}
                />

                <div style={{width:'4vh'}}></div>

                <TextField
                    style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                    autoComplete='off'
                    label='Latitude'
                    id='site-modal-name'
                    value={formData.latitude.value}
                    onChange={e => updateFormData(e.target.value, 'latitude')}
                    error={formData.latitude.error !== ''}
                    helperText={formData.latitude.error}
                />
            </div>

            <h4>Utilities</h4>

            <Typography variant="subtitle2" gutterBottom>Water</Typography>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label={<span>Unit cost ($/m<sup>3</sup>)</span>}
                id='site-modal-name'
                value={formData.unitCosts.water.value}
                onChange={e => updateFormData(e.target.value, 'unitCosts', 'water')}
                error={formData.unitCosts.water.error !== ''}
                helperText={formData.unitCosts.water.error}
            />

            <Typography variant="subtitle2" gutterBottom>Electricity</Typography>

            <div style={{display:'flex', flexDirection:'row'}}>

                <TextField
                    style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                    autoComplete='off'
                    label={<span>Unit cost ($/kWh)</span>}
                    id='site-modal-name'
                    value={formData.unitCosts.electricity.value}
                    onChange={e => updateFormData(e.target.value, 'unitCosts', 'electricity')}
                    error={formData.unitCosts.electricity.error !== ''}
                    helperText={formData.unitCosts.electricity.error}
                />

                <div style={{width:'4vh'}}></div>

                <TextField
                    style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                    autoComplete='off'
                    label={<span>Power factor</span>}
                    id='site-modal-name'
                    value={formData.unitCosts.pf.value}
                    onChange={e => updateFormData(e.target.value, 'unitCosts', 'pf')}
                    error={formData.unitCosts.pf.error !== ''}
                    helperText={formData.unitCosts.pf.error}
                />

            </div>

            <Typography variant="subtitle2" gutterBottom>Natural gas</Typography>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label={<span>Unit cost ($/m<sup>3</sup>)</span>}
                id='site-modal-name'
                value={formData.unitCosts.gas.value}
                onChange={e => updateFormData(e.target.value, 'unitCosts', 'gas')}
                error={formData.unitCosts.gas.error !== ''}
                helperText={formData.unitCosts.gas.error === '' ? 'Unit cost for natural gas. Contact dev team if other gas type is used.' : formData.unitCosts.gas.error}
            />

            <br/>

            <NewUnitCostForm updatedUnitCosts={updatedUnitCosts} clearUpcomingUnitCosts={clearUpcomingUnitCosts} saveUpcomingUnitCosts={saveUpcomingUnitCosts}/>

            <h4>Emissions factors</h4>

            <FormControlLabel control={<Checkbox checked={formData.ghgFactors.custom} onChange={() => {
                let formCopy = {...formData};

                formCopy.ghgFactors.water.value = '';
                formCopy.ghgFactors.electricity.value = '';
                formCopy.ghgFactors.gas.value = '';

                formCopy.ghgFactors.selectedJurisdiction.value = null;

                formCopy.ghgFactors.custom = !formCopy.ghgFactors.custom;

                setFormData(formCopy);
            }} />} label="This site has unique emissions factors (custom factors will not auto-update)" />

            {!formData.ghgFactors.custom && <Autocomplete
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                options={jurisdictions}
                value={
                    formData.ghgFactors.selectedJurisdiction.value != null ? 
                    `${formData.ghgFactors.selectedJurisdiction.value?.territory}, ${formData.ghgFactors.selectedJurisdiction.value?.country} (${formData.ghgFactors.selectedJurisdiction.value?.values?.year})`
                    :
                    null
                }
                onChange={(_, option) => {
                    setFormData(prev => ({
                        ...prev,
                        [`ghgFactors`]: {
                            ...prev[`ghgFactors`],
                            [`selectedJurisdiction`]: {
                                ...prev[`ghgFactors`][`selectedJurisdiction`],
                                value: option
                            },
                            [`water`]: {
                                ...prev[`ghgFactors`][`water`],
                                value: option == null ? '' : option.values.water_kg_co2_m3
                            },
                            [`electricity`]: {
                                ...prev[`ghgFactors`][`electricity`],
                                value: option == null ? '' : option.values.elec_kg_co2_kwh
                            },
                            [`gas`]: {
                                ...prev[`ghgFactors`][`gas`],
                                value: option == null ? '' : option.values.gas_kg_co2_btu

                            }
                        }
                    }));
                }}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        <span>{option.territory}, {option.country} ({option.values.year})</span>
                    </Box>
                )}
                renderInput={(params) => <TextField id='site-modal-name' style={{width:'100%'}} {...params} inputProps={{...params.inputProps}} label="Emission factors set" />}
            />}

            {formData.ghgFactors.selectedJurisdiction.error !== '' && <small style={{color:'red'}}>{formData.ghgFactors.selectedJurisdiction.error}</small>}

            <Typography variant="subtitle2" gutterBottom>Water</Typography>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label={<span>Emission factor (kg CO<sub>2</sub>e/m<sup>3</sup>)</span>}
                id='site-modal-name'
                disabled={!formData.ghgFactors.custom}
                value={formData.ghgFactors.water.value}
                onChange={e => updateFormData(e.target.value, 'ghgFactors', 'water')}
                error={formData.ghgFactors.water.error !== ''}
                helperText={formData.ghgFactors.water.error}
            />

            <Typography variant="subtitle2" gutterBottom>Electricity</Typography>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label={<span>Emission factor (kg CO<sub>2</sub>e/kWh)</span>}
                id='site-modal-name'
                disabled={!formData.ghgFactors.custom}
                value={formData.ghgFactors.electricity.value}
                onChange={e => updateFormData(e.target.value, 'ghgFactors', 'electricity')}
                error={formData.ghgFactors.electricity.error !== ''}
                helperText={formData.ghgFactors.electricity.error}
            />

            <Typography variant="subtitle2" gutterBottom>Natural gas</Typography>

            <TextField
                style={{width:'30%', marginBottom:'1.4vh', marginTop:'0.5vh'}}
                autoComplete='off'
                label={<span>Emission factor (kg CO<sub>2</sub>e/BTU)</span>}
                id='site-modal-name'
                disabled={!formData.ghgFactors.custom}
                value={formData.ghgFactors.gas.value}
                onChange={e => updateFormData(e.target.value, 'ghgFactors', 'gas')}
                error={formData.ghgFactors.gas.error !== ''}
                helperText={formData.ghgFactors.gas.error}
            />
        </div>
    );
}

export default SiteConfigPage;