const scope12EmissionsTest = [
    // { year: 2021, value: 2950, baseline: 1685, endGoal: 1317, fullImp: 980 },
    { year: 2022, value: 2457, baseline: 1685, endGoal: 2457, fullImp: 980 },
    { year: 2023, value: 2457, baseline: 1685, endGoal: 2150, fullImp: 980 },
    { year: 2024, value: 2384, baseline: 1685, endGoal: 1843, fullImp: 980 },
    { year: 2025, value: 2035, baseline: 1685, endGoal: 1536, fullImp: 980 },
    { year: 2026, value: 2003, baseline: 1685, endGoal: 1229, fullImp: 980 },
    { year: 2027, value: 2003, baseline: 1685, endGoal: 922, fullImp: 980 },
    { year: 2028, value: 2003, baseline: 1685, endGoal: 614, fullImp: 980 },
    { year: 2029, value: 2003, baseline: 1685, endGoal: 307, fullImp: 980 },
    { year: 2030, value: 2003, baseline: 1685, endGoal: 0, fullImp: 980 },
];

// const scope12EmissionsTest = [
//     // { year: 2021, value: 2950, baseline: 1685, endGoal: 1317, fullImp: 980 },
//     { year: 2022, value: 2750, baseline: 1685, endGoal: 2457, fullImp: 980 },
//     { year: 2023, value: 2700, baseline: 1685, endGoal: 2150, fullImp: 980 },
//     { year: 2024, value: 2230, baseline: 1685, endGoal: 1843, fullImp: 980 },
//     { year: 2025, value: 2100, baseline: 1685, endGoal: 1536, fullImp: 980 },
//     { year: 2026, value: 2095, baseline: 1685, endGoal: 1229, fullImp: 980 },
//     { year: 2027, value: 2080, baseline: 1685, endGoal: 922, fullImp: 980 },
//     { year: 2028, value: 2080, baseline: 1685, endGoal: 614, fullImp: 980 },
//     { year: 2029, value: 2080, baseline: 1685, endGoal: 307, fullImp: 980 },
//     { year: 2030, value: 2080, baseline: 1685, endGoal: 0, fullImp: 980 },
// ];

const scope12ReductionsTest = [
    // { year: 2021, value: 0, baseline: 1440, endGoal: 1510, fullImp: 2100 },
    { year: 2030, value: 454, baseline: 1685, endGoal: 2457, fullImp: 1477 },
    { year: 2029, value: 454, baseline: 1685, endGoal: 2150, fullImp: 1477 },
    { year: 2028, value: 454, baseline: 1685, endGoal: 1843, fullImp: 1477 },
    { year: 2027, value: 454, baseline: 1685, endGoal: 1536, fullImp: 1477 },
    { year: 2026, value: 454, baseline: 1685, endGoal: 1229, fullImp: 1477 },
    { year: 2025, value: 422, baseline: 1685, endGoal: 922, fullImp: 1477 },
    { year: 2024, value: 73, baseline: 1685, endGoal: 614, fullImp: 1477 },
    { year: 2023, value: 0, baseline: 1685, endGoal: 307, fullImp: 1477 },
    { year: 2022, value: 0, baseline: 1685, endGoal: 0, fullImp: 1477 },
].reverse();

// const scope12ReductionsTest = [
//     // { year: 2021, value: 0, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2022, value: 250, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2023, value: 275, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2024, value: 700, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2025, value: 800, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2026, value: 800, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2027, value: 800, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2028, value: 800, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2029, value: 800, baseline: 1440, endGoal: 1510, fullImp: 2100 },
//     { year: 2030, value: 800, baseline: 1440, endGoal: 1510, fullImp: 2100 },
// ];

const scope12IntensityEmissionsTest = [
    { year: 2021, value: 0.245, baseline: 0.13, fullImp: 0.07, endGoal: 0.125},
    { year: 2022, value: 0.191, baseline: 0.11, fullImp: 0.065, endGoal: 0.10 },
    { year: 2023, value: 0.16, baseline: 0.09, fullImp: 0.0575, endGoal: 0.0825 },
    { year: 2024, value: 0.12, baseline: 0.075, fullImp: 0.0525, endGoal: 0.07 },
    { year: 2025, value: 0.09, baseline: 0.065, fullImp: 0.0475, endGoal: 0.06 },
    { year: 2026, value: 0.07, baseline: 0.055, fullImp: 0.04, endGoal: 0.05 },
    { year: 2027, value: 0.06, baseline: 0.0475, fullImp: 0.035, endGoal: 0.045 },
    { year: 2028, value: 0.055, baseline: 0.045, fullImp: 0.0325, endGoal: 0.0425 },
    { year: 2029, value: 0.05, baseline: 0.0425, fullImp: 0.0275, endGoal: 0.04 },
    { year: 2030, value: 0.045, baseline: 0.04, fullImp: 0.025, endGoal: 0.04 },
];

const scope12IntensityReductionsTest = [
    { year: 2021, value: 0, baseline: 0.115, endGoal: 0.125, fullImp: 0.168 },
    { year: 2022, value: 0.0175, baseline: 0.1, endGoal: 0.105, fullImp: 0.1525 },
    { year: 2023, value: 0.015, baseline: 0.08, endGoal: 0.085, fullImp: 0.12 },
    { year: 2024, value: 0.0375, baseline: 0.0675, endGoal: 0.0725, fullImp: 0.1 },
    { year: 2025, value: 0.035, baseline: 0.0575, endGoal: 0.0625, fullImp: 0.0825 },
    { year: 2026, value: 0.03, baseline: 0.05, endGoal: 0.055, fullImp: 0.07 },
    { year: 2027, value: 0.025, baseline: 0.041, endGoal: 0.045, fullImp: 0.0575 },
    { year: 2028, value: 0.021, baseline: 0.035, endGoal: 0.0375, fullImp: 0.045 },
    { year: 2029, value: 0.018, baseline: 0.0325, endGoal: 0.035, fullImp: 0.04 },
    { year: 2030, value: 0.017, baseline: 0.03, endGoal: 0.031, fullImp: 0.0375 },
];

const financialSavingsTest = [
    // { year: 2021, value: 0 },
    { year: 2022, value: 0 },
    { year: 2023, value: 0 },
    { year: 2024, value: 94090 },
    { year: 2025, value: 252368 },
    { year: 2026, value: 263968 },
    { year: 2027, value: 263968 },
    { year: 2028, value: 263968 },
    { year: 2029, value: 263968 },
    { year: 2030, value: 263968 },
];

// const financialSavingsTest = [
//     { year: 2021, value: 0 },
//     { year: 2022, value: 40000 },
//     { year: 2023, value: 40000 },
//     { year: 2024, value: 124000 },
//     { year: 2025, value: 124000 },
//     { year: 2026, value: 124000 },
//     { year: 2027, value: 124000 },
//     { year: 2028, value: 124000 },
//     { year: 2029, value: 124000 },
//     { year: 2030, value: 124000 },
// ];

const financialSavingsProdTest = [
    { year: 2021, value: 0 },
    { year: 2022, value: 3 },
    { year: 2023, value: 2.75 },
    { year: 2024, value: 6.10 },
    { year: 2025, value: 9.15 },
    { year: 2026, value: 8 },
    { year: 2027, value: 6.35 },
    { year: 2028, value: 4 },
    { year: 2029, value: 3.5 },
    { year: 2030, value: 3 },
];

const scope123EmissionsTest = [
    { year: 2021, value: 162000, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2022, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2023, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2024, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2025, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2026, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2027, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2028, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2029, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
    { year: 2030, value: 162500, baseline: 160000, endGoal: 80000, fullImp: 155000 },
];

const scope123ReductionsTest = [
    { year: 2021, value: 0, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2022, value: 250, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2023, value: 275, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2024, value: 700, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2025, value: 800, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2026, value: 800, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2027, value: 800, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2028, value: 800, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2029, value: 800, baseline: 3500, endGoal: 82000, fullImp: 8000 },
    { year: 2030, value: 800, baseline: 3500, endGoal: 82000, fullImp: 8000 },
];

const financialSavingsScope123Test = [
    { year: 2021, value: 0 },
    { year: 2022, value: 50000 },
    { year: 2023, value: 55000 },
    { year: 2024, value: 125000 },
    { year: 2025, value: 175000 },
    { year: 2026, value: 175000 },
    { year: 2027, value: 175000 },
    { year: 2028, value: 175000 },
    { year: 2029, value: 175000 },
    { year: 2030, value: 175000 },
];

const financialSavingsScope123ReductionsTest = [
    { year: 2021, value: 0 },
    { year: 2022, value: 3 },
    { year: 2023, value: 2.75 },
    { year: 2024, value: 6.15 },
    { year: 2025, value: 9.25 },
    { year: 2026, value: 8.75 },
    { year: 2027, value: 6.5 },
    { year: 2028, value: 5.40 },
    { year: 2029, value: 4.5 },
    { year: 2030, value: 3.81 },
];

const scope123IntensityTest = [
    { year: 2021, value: 13.5, baseline: 12.5, fullImp: 11, endGoal: 6.5 },
    { year: 2022, value: 11, baseline: 10, fullImp: 8.5, endGoal: 5.5 },
    { year: 2023, value: 9.25, baseline: 8.5, fullImp: 7, endGoal: 5 },
    { year: 2024, value: 7.75, baseline: 7, fullImp: 5.5, endGoal: 4.5 },
    { year: 2025, value: 6.5, baseline: 6, fullImp: 4.75, endGoal: 4.25 },
    { year: 2026, value: 5.5, baseline: 5, fullImp: 4.25, endGoal: 3.75 },
    { year: 2027, value: 4.5, baseline: 4, fullImp: 3.5, endGoal: 3 },
    { year: 2028, value: 3.85, baseline: 3.5, fullImp: 3, endGoal: 2.75 },
    { year: 2029, value: 3, baseline: 2.75, fullImp: 2.25, endGoal: 2 },
    { year: 2030, value: 2.3, baseline: 2.25, fullImp: 2, endGoal: 1.5 },
];

const scope123IntensityReductionsTest = [
    { year: 2021, value: 1, baseline: 2, fullImp: 3.5, endGoal: 6.5 },
    { year: 2022, value: 1.5, baseline: 2, fullImp: 3, endGoal: 5.7 },
    { year: 2023, value: 1.25, baseline: 2, fullImp: 2.75, endGoal: 4.75 },
    { year: 2024, value: 1.2, baseline: 2, fullImp: 2.5, endGoal: 4 },
    { year: 2025, value: 1.2, baseline: 2, fullImp: 2.25, endGoal: 3.25 },
    { year: 2026, value: 1.2, baseline: 2, fullImp: 2.25, endGoal: 3.1 },
    { year: 2027, value: 1.2, baseline: 2, fullImp: 2.1, endGoal: 3 },
    { year: 2028, value: 1.2, baseline: 2, fullImp: 2.1, endGoal: 2.75 },
    { year: 2029, value: 1.2, baseline: 2, fullImp: 2.1, endGoal: 2.6 },
    { year: 2030, value: 1.2, baseline: 2, fullImp: 2.05, endGoal: 2.5 },
];

module.exports = {
    scope12EmissionsTest,
    scope12ReductionsTest,
    scope12IntensityEmissionsTest,
    scope12IntensityReductionsTest,
    financialSavingsTest,
    financialSavingsProdTest,
    scope123EmissionsTest,
    scope123ReductionsTest,
    financialSavingsScope123Test,
    financialSavingsScope123ReductionsTest,
    scope123IntensityTest,
    scope123IntensityReductionsTest,
}